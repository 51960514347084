import React, { useEffect, useState, useRef } from 'react'

import "./Carousel.scss";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);


  return ref;
}

const TextCarousel = ({ words, title, titleClasses, wordsClasses, time }) => {
  const defaultTime = 3000;
  const [wordsState, setWordsState] = useState({});
  const previous = usePrevious(wordsState);

  useEffect(() => {
    setWordsState({
      [words[0]]: "active"
    });

    let i = 0;
    let dir = 1;

    const interval = setInterval(() => {
      let newState = previous.current;
      newState = {
        ...newState,
        [words[i]]: dir === 1 ? "top" : "bottom"
      };

      i = i + 1;

      if (i === words.length) {
        dir = dir === 1 ? -1 : 1;
        i = 0;
      }

      newState = {
        ...newState,
        [words[i]]: "active"
      }

      setWordsState(newState);

    }, time || defaultTime)
    return () => {
      clearInterval(interval);
    }
  }, []);
  return (
    <div className="text-carousel">
      <span className={`align-middle ${titleClasses.join(' ')}`}>{title}</span>
      <span className={`relative align-middle ${wordsClasses.join(' ')}`}>
        &nbsp;
        {words.map((word) => (
          <span key={word} className={`word ` + (wordsState[word] || "bottom")}>{word}</span>
        ))}
      </span>
    </div>
  )
}

export default TextCarousel