import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import nextIssue from 'utils/nextIssue';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo from 'utils/updateQuizInfo';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';

const QuizHeartHealthOne = () => {
  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'heart-health-1' });
      sessionStorage.setItem(
        'current_health_issue',
        JSON.stringify('heart-health'),
      );
    } 
  }, [si]);

  useEffect(() => {
    if (active) {
      const heartAnswer = active.toUpperCase();
      updateQuizInfo({ heart_q1: heartAnswer });
      updateHealthIssuesAnswers({ heart_q1: active === 'yes' ? true : false });
      nextIssue(navigate);
    }
  }, [active]);

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Have a diagnosed heart condition?"
        />
        <div className="flex w-full flex-col  gap-6 md:flex-row md:justify-center">
          <button
            onClick={() => setActive('yes')}
            className={`p-3 text-lg font-bold ${
              active === 'yes'
                ? ' bg-dteal-400 text-white '
                : ' bg-white text-dteal-400 '
            }`}>
            Yes
          </button>
          <button
            onClick={() => setActive('no')}
            className={`p-3  text-lg font-bold ${
              active === 'no'
                ? ' bg-dteal-400 text-white '
                : ' bg-white text-dteal-400 '
            }`}>
            No
          </button>
        </div>
      </div>
    </section>
  );
};

export default QuizHeartHealthOne;
