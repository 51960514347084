import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizJointCareThree = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.joint_q3) {
      setOption(info.joint_q3);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'joint-care-3' });
    } 
  }, [si]);
  const [option, setOption] = useState(1);
  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];
  const handleClick = () => {
    if (option) {
      updateQuizInfo({ joints_q3: option });
      updateHealthIssuesAnswers({ joints_q3: option });
      navigate(`/quiz/joint-care-4/?si=${si}`);
    }
  };

  const dotDetailsData = [
    {
      title: 'Not at all',
      body: 'My dog has no trouble going up/down stairs',
    },
    {
      title: 'Somewhat',
      body: 'My dog is sometimes tentative going up/down stairs',
    },
    {
      title: 'Significantly',
      body: 'My dog is very reluctant to go up/down stairs and will avoid if possible',
    },
  ];
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="px-9 container mx-auto flex h-screen flex-col items-center justify-center gap-9">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Show reluctance to go up/down stairs?"
        />

        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation  handleBack={()=>navigate(-1)}   handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizJointCareThree;
