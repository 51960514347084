import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import './Slider.scss';

export const Scroller = ({ children, className }) => {
  return <div className={classNames('scroller', className)}>{children}</div>;
};

export const ScrollerButtonType = {
  next: 'scroller__button__next',
  prev: 'scroller__button__prev',
};

export const ScrollerButton = ({
  type,
  children,
  className,
  scrollLenght = 320,
}) => {
  const buttonType = type || ScrollerButtonType.next;
  const [parentScroller, setParentScroller] = useState(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }
    setParentScroller(document.querySelector('.scroller'));
  }, [buttonRef]);

  const scrollHandler = () => {
    if (buttonType === ScrollerButtonType.next) {
      parentScroller.scrollBy(scrollLenght, 0);
    } else if (buttonType === ScrollerButtonType.prev) {
      parentScroller.scrollBy(-scrollLenght, 0);
    }
  };

  return (
    <div
      ref={buttonRef}
      className={classNames('scroller__button', buttonType, className)}
      onClick={scrollHandler}>
      {children}
    </div>
  );
};
