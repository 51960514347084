import React from 'react'

import Heading, { Colors } from '../../../components/typography/Heading';

import Circle1 from "../../../assets/images/aboutUs/Circle1.png";
import Circle2 from "../../../assets/images/aboutUs/Circle2.png";
import Circle3 from "../../../assets/images/aboutUs/Circle3.png";
import Circle4 from "../../../assets/images/aboutUs/Circle4.png";
import Circle5 from "../../../assets/images/aboutUs/Circle5.png";
import Circle6 from "../../../assets/images/aboutUs/Circle6.png";

const data = {
  heading: "We can provide bespoke formulas for hundreds of dog breeds",
}

const images = [{
  url: Circle1,
  alt: "Lucky - 4 years old, Golden Retriever"
}, {
  url: Circle2,
  alt: "Morag - 3 years old, Australian Shephard",
}, {
  url: Circle3,
  alt: "Rocky - 4 years old, Basenji",
}, {
  url: Circle4,
  alt: "Juno - 2 years old, Dachshund",
}, {
  url: Circle5,
  alt: "Ruby - 11 years old, Pyrenean Shepherd",
}, {
  url: Circle6,
  alt: "Milo - 7 years old, St Bernard",
}];

const BreedsSection = () => {
  return (
    <section className="container py-12 px-16">
      <Heading className="text-center" color={Colors.orange}>{data.heading}</Heading>
      <div className="grid-cols-2 lg:grid-cols-3 gap-x-3.5 md:gap-x-8 gap-y-5 md:gap-y-14 items-center justify-items-center max-lg:mt-7 mt-12" style={{ display: 'grid' }}>
        {images.map(({ url, alt }, key) => (
          <img className="block w-[7rem] h-[7rem] md:w-auto md:h-auto" src={url} alt={alt} key={key} />
        ))}
      </div>
    </section>
  )
}

export default BreedsSection