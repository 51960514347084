import React from "react";

import "./MultipleBreedsInfoModal.scss";

import { ReactComponent as Close } from "../../../../assets/icons/close.svg";

const MultipleBreedsInfoModal = ({ setModal, modalType }) => {
  return (
    <div
      className="multipleBreedsInfoModal"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setModal(false)
        }
      }}
    >
      <div className="multipleBreedsInfoModal__modal">
        <Close onClick={() => setModal(false)} />
        <div className="multipleBreedsInfoModal__title-name">{modalType.header}</div>
        <div className="multipleBreedsInfoModal__about">
          <p>
            {modalType.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MultipleBreedsInfoModal;