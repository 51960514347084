import { Link } from 'react-router-dom';

import { ReactComponent as TickInCircleSvg } from '../../assets/icons/tick-in-circle.svg';
import { ReactComponent as CloseInCircleSvg } from '../../assets/icons/close-in-circle.svg';
import TDIULogo from '../../assets/logos/logo.svg';
import TDIULogoSmall from '../../assets/logos/logo-small.svg';
import YouMoveLogo from '../../assets/logos/YuMove.webp';
import VetPlusLogo from '../../assets/logos/VetPlus.webp';
import PetLabCoLogo from '../../assets/logos/PetLabCo.webp';
import TextCarousel from 'components/TextCarousel';

import { ReactComponent as LongArrowLeft } from '../../assets/icons/long-arrow-left.svg';
import { ReactComponent as LongArrowRight } from '../../assets/icons/long-arrow-right.svg';
import {
  Scroller,
  ScrollerButton,
  ScrollerButtonType,
} from 'components/Slider/Slider';

const Home = () => {
  const supplementBenefits = [
    {
      heading: 'Pro-Planet',
      copy: 'No to plastic waste, yes to compostable paper refills',
    },
    {
      heading: 'Perfectly Personalised',
      copy: 'Their unique formula, say goodbye to trial and error',
    },
    {
      heading: 'Better Value',
      copy: 'One complete supplement, for less than a daily coffee',
    },
    {
      heading: 'More Convenient',
      copy: 'Tasty meal topper, delivered through your letterbox',
    },
  ];

  const tailoredSupplements = [
    'Joint care',
    'Itchy skin',
    'Digestive issues',
    'Arthritis',
    'Anxiety',
    'Dental health',
    'Destructive behaviour',
    'Smelly breath',
  ];

  const howItWorks = [
    {
      heading: 'Step 1',
      subheading: 'Take our quiz:',
      copy: 'Tell us all about your dog and their health.',
    },
    {
      heading: 'Step 2',
      subheading: 'Made to order:',
      copy: 'We make your dog’s unique supplement in our dedicated lab',
    },
    {
      heading: 'Step 3',
      subheading: 'Delivered to your door:',
      copy: 'Your dog’s first supplement, spoon, and personalised tin arrive!',
    },
    {
      heading: 'Step 4',
      subheading: 'Planet friendly refills:',
      // copy: 'We send your dog’s refill pack every 30 or 60 days.',
      copy: 'We send your dog’s refill pack every 30 days.',
    },
  ];

  const advantages = [
    {
      title: 'More personalised',
      text: 'This Dog Is Unique provides the right level of care for your dog and allows pet parents to take a proactive approach with preventive support.',
      imgSrc: 'img/advantages/advantage-more-personalised.png',
      imgClasses: ['px-[30px]'],
    },
    {
      title: 'Better Value',
      text: 'On average, customers feeding their dog more than two supplements save over £20 a month by switching to This Dog Is Unique.',
      imgSrc: 'img/advantages/advantage-better-value.png',
      imgClasses: ['px-[30px]'],
    },
    {
      title: 'More Convenient',
      text: 'No more hard to feed pills or overly fattening chews - our powdered supplement with your very own made to measure spoon removes all the hassle.',
      imgSrc: 'img/advantages/advantage-more-convenient.png',
      images: [
        {
          src: 'img/advantages/advantage-more-convenient.png',
          classes: [
            'absolute',
            'left-[0] max-lg:left-[auto] max-lg:right-0',
            'w-[46vw] max-lg:w-[53vw]',
            'translate-x-[-6vw] max-lg:translate-x-[11vw]',
            'translate-y-[-18vw] max-lg:translate-y-[-3vw]',
            'scale-[1.6] max-lg:scale-x-[-1.6] max-lg:scale-[1.6]',
            'max-lg:rotate-[24deg]',
          ],
        },
        {
          src: 'img/advantages/advantage-more-convenient-2.png',
          classes: ['lg:hidden', 'absolute', 'left-[0]', 'w-[40vw]'],
        },
      ],
    },
  ];

  const comparisonCriterias = [
    {
      first: '100%',
      second: 'Personalised',
    },
    {
      first: 'Perfectly',
      second: 'portioned',
    },
    {
      first: 'Evolves',
      second: 'as dog ages',
    },
    {
      first: 'No',
      second: 'plastic',
    },
    {
      first: 'Free',
      second: 'personalised tin',
    },
  ];

  const healthConcerns = [
    'Skin care',
    'Diet & nutrition',
    'Destructive behavior',
    'Itching',
    'Anxiety',
    'Heart Health',
    'Mobility',
    'Smelly breath',
    'Joint care',
    'Immunity',
    'Dental health',
    'Skin issues',
  ];

  const moreSupplement = [
    {
      bg: 'vetaccess',
      heading: '24/7 Vet Access',
      copy: 'As a subscriber, you can get free 24/7 access to PawSquad for advice from a qualified Veterinary Surgeon about the health and wellbeing of your dog, whether it’s a small niggle or in-depth chat.',
    },
    {
      bg: 'evaluate',
      heading: 'Evaluate & Elevate',
      copy: 'Your dog’s health is our number one priority, which is why we have built Evaluate and Elevate, our proprietary aftercare system. Our aftercare system allows your dog’s formula to adjust and change as their healthcare evolves.',
    },
    {
      bg: 'exclusive',
      heading: 'Exclusive Partnerships',
      copy: 'We’ve partnered with brands who love dogs just as much as we do. As a This Dog Is Unique customer, you’ll get invited to special promotions, webinars and more!',
    },
  ];

  const doctorTestimonialData = {
    title: 'A brief word from our Head Vet',
    doctorSubtitle: 'Dr Rebecca Massie BVetMed MRCVS\nVeterinary Surgeon',
    text: `“As a vet, I always want the best for every animal I treat, and that's why I only selected the best, clinically proven ingredients for your tailored supplement to help make your dog's life healthier and happier.”`,
  };

  return (
    <>
      {/* 
      <AltJumbotron/>
      <Marquee />
      <OurBenefits/>
      <AltHowItWorks/>
      <DogStudyCard />
      <MuchMore/>
      <BackedTeam />
      <BackedTeamBanner />
      <UniqueChart /> */}

      {/** HERO SECTION */}
      {/* OLD DESIGN */}
      {/* <section
        className="mt-[7.2rem] h-[42rem] bg-dorange-400 bg-homeheromd bg-cover bg-bottom                    
                        bg-no-repeat p-9 px-6
                        pt-12 max-lg:px-[32px] max-[1000px]:hidden max-sm:bg-[length:315px] sm:bg-homeherolg sm:px-12 sm:pt-20">
        <div
          className="container mx-auto flex flex-col
                        justify-between gap-[20px] max-lg:px-0 sm:h-72 min-[640px]:justify-start
                        lg:gap-7
                        lg:gap-9">
          <h2
            className="font-title text-[26px]
                         font-semibold text-white
                         max-lg:mt-[20px] min-[391px]:text-[7.5vw] sm:w-[26rem] sm:text-4xl lg:w-[50rem] 
                         lg:text-7xl">
            The only supplement as unique as your dog
          </h2>

          <p
            className="font-body text-[1rem]
                        text-white sm:w-2/3
                        lg:w-[17.5rem] lg:w-[32rem] lg:text-xl">
            All your dog’s unique health needs covered in one made to order
            daily supplement. Uniquely formulated just for your dog with
            clinically backed ingredients.
          </p>

          <Link
            to={`/quiz/dogName`}
            className="max-w-fit bg-dteal-400 font-button
                              text-xs font-semibold uppercase
                              text-white max-lg:mt-[10px]
                              max-lg:py-[1rem] max-lg:px-[1.3rem]
                              max-lg:text-[0.9rem] lg:p-2 lg:px-4 lg:text-lg">
            Take the Quiz
          </Link>
        </div>
      </section> */}

      {/* NEW DESIGN */}
      <section className="relative min-h-[532px] w-full bg-cover bg-[center_top_-10px] bg-bottom bg-no-repeat max-md:bg-homefirstsection max-[648px]:mt-[135px] min-[649px]:mt-[150px] md:mt-[132px] md:bg-[#E2EAEC]">
        <div className="mx-auto w-full max-w-[1536px] justify-between md:flex">
          <div className="md:sticky md:top-[130px] md:mb-16 md:h-fit md:w-[50%]">
            <div className="p-[18px] pb-0 max-md:bg-[#E2EAEC] max-md:shadow-[1px_18px_58px_49px_rgb(226,234,236,1)] md:p-16">
              <h2 className="text-center font-title text-2xl font-semibold text-dteal-400 lg:text-4xl lg:leading-[3.2rem]">
                <span className="inline bg-white">
                  Personalised supplements
                  <br />
                  as<span className="text-dorange-200">{' unique '}</span>as
                  your dog
                </span>
              </h2>
              <p className="mx-auto w-[292px] text-center text-sm font-semibold leading-4 text-dteal-400 max-lg:mt-3 md:w-[80%] lg:mt-8 lg:text-2xl">
                100% personalised supplements to support all your dog’s unique
                health concerns using only clinically backed ingredients.
              </p>
            </div>

            <Link
              to="/quiz/dogName"
              className="relative z-10 mx-auto block max-w-fit bg-dteal-400 py-2
                      px-12 font-button text-xl
                      font-semibold text-white max-lg:mt-3 lg:mt-8
                      lg:text-3xl">
              Take the quiz
            </Link>
          </div>
          <div className="min-h-[532px] w-[50%] max-md:hidden">
            <img
              src="/img/home-first-section.webp"
              alt="This Dog Is Unique"
              className="w-full"
            />
          </div>
        </div>
      </section>

      {/** TAILORED SUPPLEMENTS */}
      <section>
        <div className="container mx-auto flex h-[164px] items-center max-lg:h-[106px] max-lg:pl-[40px]">
          <h3 className="font-title text-xl font-semibold lg:text-[36px]">
            {/**  
              <div className="inline indent-2">
                {tailoredSupplements.map((supplement) => {
                  return <span className="text-dorange-400">{supplement}</span>;
                })}
              </div>
            */}
            <TextCarousel
              time={1500}
              title="Tailored supplements for"
              titleClasses={['text-dteal-400']}
              words={[
                'Skin care',
                'Digestion',
                'Joint Care',
                'Anxiety',
                'Joint care & Mobility',
                'Skin & coat',
                'Smelly breath',
                'Destructive behaviour',
              ]}
              wordsClasses={[
                'max-lg:block',
                'lg:ml-[16px]',
                'text-dorange-400',
              ]}
            />
          </h3>
        </div>
      </section>

      {/** Supplement section */}
      <section className="overflow-hidden bg-dteal-50 max-lg:py-12 lg:py-24">
        <div className="flex flex-col gap-12 lg:container lg:mx-auto">
          <div className="flex flex-col items-center">
            <h3 className="text-center font-title text-xl font-semibold leading-tight text-dteal-400 max-lg:px-[70px] sm:text-[24px] md:text-[34px] lg:w-[57rem] lg:text-4_5xl">
              There’s a reason we are the world leader in personalised dog
              supplements
            </h3>
          </div>
          <div
            className="flex flex-col
                        lg:flex-row-reverse lg:justify-between">
            {/** Supplements shot */}
            <div className="max-lg:relative max-lg:left-[15vw] lg:-mb-6 lg:w-1/2">
              <img src="img/supplements.png" className="lg:min-w-fit" alt="" />
            </div>

            {/** Supplement benefits */}
            <div className="flex flex-col gap-12 max-lg:gap-[28px]  max-lg:pl-[17vw] max-lg:pt-14 lg:w-1/2 lg:pt-24 lg:pl-32">
              {supplementBenefits.map((benefit, idx) => {
                return (
                  <div
                    key={`supplement-benefits-${idx}`}
                    className="max-lg:max-w-[232px]">
                    <h4 className="font-title text-xl font-semibold text-dorange-400 lg:text-4xl">
                      {benefit.heading}
                    </h4>
                    <p className="text-sm max-lg:mt-2.5 lg:mt-7 lg:w-72 lg:text-xl">
                      {benefit.copy}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/** How it works */}
      <section>
        <div
          className="relative flex flex-col
                        bg-dteal-100 lg:flex-row">
          {/** How it works - shot */}
          <div
            className="relative bg-cover bg-no-repeat max-lg:h-96 max-lg:w-full 
              max-lg:bg-howitworks max-lg:bg-[center_top_-6rem] lg:h-192 lg:w-[43%]">
            <img
              src="img/how-it-works.jpg"
              className="absolute top-[112px] right-0 z-10 w-[500px] max-xl:top-[56px] max-xl:left-[56px] max-lg:hidden"
              alt="how it works"
            />
          </div>

          {/** How it works - steps */}
          <div className="flex flex-col justify-center gap-[26px] py-[32px] lg:w-[57%] lg:gap-16">
            <div>
              <h3 className="ml-8 font-title text-xl font-semibold text-white max-xl:ml-9 lg:ml-20 lg:text-4_5xl ">
                How it works
              </h3>
            </div>
            <Scroller className="scrollbar-hidden overflow-x-auto overflow-y-hidden">
              <div className="flex w-max flex-row gap-6 lg:gap-12">
                {howItWorks.map((step, idx) => {
                  return (
                    <div
                      key={idx}
                      className="slider inline-block first-of-type:ml-16 last-of-type:mr-8 max-lg:first-of-type:ml-9">
                      <div
                        className="justify-baseline flex h-40 w-40  flex-col items-center rounded-full
                                      bg-dteal-400 px-2 pt-[24px] lg:h-80 lg:w-80 lg:gap-1">
                        <h4 className="text-center font-title text-lg font-semibold  text-white max-lg:mb-[14px] lg:mb-9 lg:mt-12 lg:text-4_5xl">
                          {step.heading}
                        </h4>
                        <h5 className="text-center font-title font-semibold text-white max-lg:mb-[8px] max-lg:text-[11px] lg:text-lg">
                          {step.subheading}
                        </h5>
                        <p className="font-regular text-center text-white max-lg:max-w-[105px] max-lg:text-[11px] lg:px-12  lg:text-lg">
                          {step.copy}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Scroller>
            <div className="ml-[136px] flex gap-7 max-lg:hidden">
              <ScrollerButton
                type={ScrollerButtonType.prev}
                scrollLenght={320}
                className="cursor-pointer">
                <LongArrowLeft />
              </ScrollerButton>
              <ScrollerButton
                type={ScrollerButtonType.next}
                scrollLenght={320}
                className="cursor-pointer">
                <LongArrowRight />
              </ScrollerButton>
            </div>
          </div>
        </div>
      </section>

      {/** Your dog is unique */}
      <section
        className="bg-dorange-200 px-[42px]
                        py-[38px] lg:pb-24 lg:pt-64">
        <div className="flex flex-col max-lg:ml-0">
          <h3 className="text-left font-title text-[26px] font-semibold leading-tight text-white lg:mx-auto lg:text-[44px]">
            Your dog is unique, shouldn’t their supplements be too?
          </h3>
        </div>
      </section>

      {/** Bella */}
      {/** 
      <section>
        <div className=" mx-auto flex flex-row bg-dteal-50">
          <div className="w-1/2 bg-orange-100">image</div>
          <div className="flex w-1/2 flex-col gap-2 p-3">
            <h3 className="font-title text-lg font-semibold text-dteal-400">
              Bella
            </h3>
            <h4 className="text-sm">6 Year Old Pomeranian</h4>
            <p className="text-xs">
              Bella came to us with osteoarthritis, anxiety and itchy skin. So
              Bella’s pet parent Lily completed our quiz and we formulated a
              bespoke supplement for Bella’s needs.
            </p>
          </div>
        </div>
      </section>
       */}

      {/** Combinations */}
      {/** 
      <section className="bg-gray-50 px-8  py-11">
        <div className="container mx-auto">
          <h3 className="font-title text-xl font-semibold text-dteal-400">
            Our engine created the best possible ingredients combination:
          </h3>
        </div>
      </section>
       */}

      {/** Advantages */}
      <section className="relative overflow-hidden py-32 max-sm:py-12">
        <div className="container">
          {advantages.map((advantage, idx) => (
            <div
              key={`advantage-${idx}`}
              className="flex items-center justify-center first-of-type:mt-0 max-lg:ml-0 max-lg:mt-12 max-lg:flex-col max-lg:flex-col-reverse max-sm:px-[41px] lg:mt-24">
              <div className="w-[660px] min-w-[470px] max-w-[375px] max-lg:mb-[34px] max-lg:h-[40vw] max-lg:w-[28rem] max-sm:w-full max-sm:px-12">
                {advantage.images ? (
                  advantage.images.map((img, idx) => (
                    <img
                      key={`${advantage.title}-${idx}`}
                      src={img.src}
                      className={`${img.classes?.join(' ')}`}
                      alt={advantage.title}
                    />
                  ))
                ) : (
                  <img
                    src={advantage.imgSrc}
                    className={`w-full ${advantage.imgClasses?.join(' ')}`}
                    alt={advantage.title}
                  />
                )}
              </div>
              <div className="max-w-[460px] lg:ml-[7rem]">
                <div className="mb-[3rem] flex max-lg:mb-[1.5rem] max-lg:ml-[12px]">
                  <h4 className="font-title text-[7rem] font-semibold max-lg:text-[5rem]">
                    {idx + 1}
                  </h4>
                  <h4 className="ml-10 flex max-w-[100px] items-center font-title text-4_5xl font-semibold max-lg:ml-5 max-lg:text-[1.6rem]">
                    {advantage.title}
                  </h4>
                </div>
                <p className="max-w-[28rem] max-lg:text-center max-md:text-sm">
                  {advantage.text}
                </p>
              </div>
            </div>
          ))}
        </div>
        <Link
          to={`/quiz/dogName`}
          className="relative z-10 mx-auto block max-w-fit
                              bg-dorange-200 p-2 px-3
                              font-button text-[13px]
                              font-semibold uppercase text-white
                              sm:p-3 sm:px-5 lg:mt-[156px] lg:text-[23px]">
          Start your dog's journey now
        </Link>
      </section>

      {/** Health concerns */}
      <section
        className="relative overflow-hidden bg-dgray-50 px-8
                        py-11 lg:py-32">
        <img
          src="img/advantages/advantage-more-convenient.png"
          alt=""
          className="absolute right-[-20vw] w-[40vw] translate-y-[-9vw] rotate-[-29deg] scale-x-[-1]"
        />
        <img
          src="img/health-concerns-coffee.png"
          alt=""
          className="absolute left-[-12vw] w-[42vw] translate-y-[4vw]"
        />
        <div className="container mx-auto flex flex-col items-center gap-5">
          <div className="flex flex-col items-center gap-2.5 max-[850px]:max-w-[605px] lg:gap-9">
            <h3 className="text-center font-title text-xl font-semibold leading-tight text-dteal-400 sm:text-[24px] sm:text-[24px] md:text-[34px] md:text-[34px] lg:w-[57rem] lg:w-[57rem] lg:text-4_5xl">
              We can formulate a single supplement to support a number of health
              concerns
            </h3>
            <p className="text-md text-center lg:text-xl">
              All for less than the price of a daily cup of coffee, starting
              from £0.66p per day.
            </p>
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-2 lg:w-[700px]">
            {healthConcerns.map((concern) => {
              return (
                <div
                  className="flex h-32 w-32
                                flex-col items-center
                                justify-center rounded-full
                                 bg-dteal-100 lg:mb-6 lg:h-48 lg:w-48">
                  <h4 className="p-3 text-center font-button text-lg text-dteal-400">
                    {concern}
                  </h4>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-center">
            <a
              href="/quiz/dogName"
              className=" max-w-fit bg-dteal-400 p-2 px-4 font-button font-semibold uppercase text-white md:p-3 md:px-5 md:text-lg">
              Take the Quiz
            </a>
          </div>
        </div>
      </section>

      {/** Doctor's testimonial */}
      {/**<section className=" bg-dorange-200 p-11 lg:py-20">
        <div className="container mx-auto flex flex-col items-center max-md:gap-[2rem] md:gap-5 lg:flex-row">
          <h3 className="text-center font-title text-[26px] font-semibold leading-tight text-white lg:mx-auto lg:hidden">
            {doctorTestimonialData.title}
          </h3>

          <div className="flex flex-col items-center gap-6 lg:w-1/2">
            <div className="h-40 w-40 rounded-full bg-drshot bg-cover bg-center bg-no-repeat lg:h-80 lg:w-80" />
            <p className="whitespace-pre text-center font-title font-semibold leading-tight max-md:text-[12px] md:text-[18px] lg:text-lg">
              {doctorTestimonialData.doctorSubtitle}
            </p>
          </div>

          <div className="lg:w-1/2">
            <h3 className="font-title text-[26px] font-semibold leading-tight text-white max-lg:hidden lg:mx-auto lg:mb-[68px] lg:text-left lg:text-[44px]">
              {doctorTestimonialData.title}
            </h3>

            <p className="font-title font-semibold leading-tight text-white max-lg:text-center sm:text-[16px] md:text-[20px] lg:mb-6  lg:text-[32px]">
              {doctorTestimonialData.text}
            </p>
          </div>
        </div>
      </section>*/}

      {/** Comparison table */}
      <section className="py-[50px] max-lg:py-[31px]">
        <div className="container">
          <h3 className="mx-auto text-center font-title text-xl font-semibold leading-tight text-dteal-400 max-lg:max-w-[72vw] sm:text-[24px] md:text-[34px] lg:w-[57rem] lg:text-4_5xl">
            The only supplement they will ever need
          </h3>
          <table className="mx-auto mt-[86px] max-lg:mt-[25px]">
            <tbody>
              <tr className="h-[60px] border-b-2 border-b-dteal-100">
                <th className="w-[500px] max-lg:w-[128px]"></th>
                <th className="bg-dteal-100 p-[20px] max-lg:p-[8px]">
                  <img
                    src={TDIULogo}
                    alt="ThisIsUnique"
                    className="max-lg:hidden"
                  />
                  <img
                    src={TDIULogoSmall}
                    alt="ThisIsUnique"
                    className="lg:hidden"
                  />
                </th>
                <th className="p-[20px] max-lg:p-[8px]">
                  <img
                    src={YouMoveLogo}
                    alt="YouMove"
                    className="max-lg:rotate-[-45deg] max-lg:scale-75"
                  />
                </th>
                <th className="p-[20px] max-lg:p-[8px]">
                  <img
                    src={VetPlusLogo}
                    alt="VetPlus"
                    className="max-lg:rotate-[-45deg] max-lg:scale-75"
                  />
                </th>
                <th className="p-[20px] max-lg:p-[8px]">
                  <img
                    src={PetLabCoLogo}
                    alt="PetLabCo"
                    className="max-lg:rotate-[-45deg] max-lg:scale-75"
                  />
                </th>
              </tr>
              {comparisonCriterias.map((criteria, idx) => (
                <tr
                  key={`comparison-item-${idx}`}
                  className="h-[60px] border-b-2 border-b-dteal-100 text-center">
                  <td className="pl-5 text-left font-button text-[22px] font-semibold uppercase text-dteal-400 max-lg:pl-1 max-lg:text-[14px] max-sm:text-[10px]">
                    <span className="text-dorange-200">{criteria.first}</span>
                    {' ' + criteria.second}
                  </td>
                  <td className="bg-dteal-100">
                    <TickInCircleSvg
                      className="inline rounded-[50%] bg-white max-lg:scale-75"
                      width="32px"
                      height="32px"
                    />
                  </td>
                  <td>
                    <CloseInCircleSvg
                      className="inline max-lg:scale-75"
                      width="32px"
                      height="32px"
                    />
                  </td>
                  <td>
                    <CloseInCircleSvg
                      className="inline max-lg:scale-75"
                      width="32px"
                      height="32px"
                    />
                  </td>
                  <td>
                    <CloseInCircleSvg
                      className="inline max-lg:scale-75"
                      width="32px"
                      height="32px"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <a
            href="/quiz/dogName"
            className="mx-auto mt-9 block
                              max-w-fit bg-dteal-400 p-2
                              px-3 font-button
                              text-xs font-semibold uppercase
                              text-white sm:p-3 sm:px-5 lg:text-lg">
            Take the Quiz
          </a>
        </div>
      </section>

      {/** More than a supplement */}
      <section className="bg-dteal-200 py-11 max-lg:pt-0 lg:px-7">
        <h3 className="mx-auto mb-[85px] text-center font-title text-xl font-semibold leading-tight text-dteal-400 max-lg:hidden max-lg:max-w-[72vw] max-lg:text-[5vw] lg:text-4_5xl">
          So much more than just a supplement
        </h3>
        <div className="flex flex-col justify-center gap-5 lg:flex-row lg:items-start">
          {/**<div className="flex flex-col gap-4">
            <div className="mx-auto h-[360px] w-full bg-dteal-400 bg-vetaccess bg-cover bg-center bg-no-repeat lg:h-56 lg:w-56 lg:rounded-full"></div>
            <div className="mx-auto flex max-w-[290px] flex-col gap-2 text-center max-lg:max-w-[70%] max-sm:max-w-[85%]">
              <h4 className="font-title text-[26px] text-white">
                24/7 Vet Access
              </h4>
              <p className="font-body">
                As a subscriber, you can get free 24/7 access to PawSquad for
                advice from a qualified Veterinary Surgeon about the health and
                wellbeing of your dog, whether it’s a small niggle or in-depth
                chat.
              </p>
            </div>
          </div>*/}
          <div className="flex flex-col gap-4">
            <div className="mx-auto h-[360px] w-full bg-dteal-400 bg-evaluate bg-cover bg-center bg-no-repeat lg:h-56 lg:w-56 lg:rounded-full"></div>
            <div className="mx-auto flex max-w-[290px] flex-col gap-2 text-center max-lg:max-w-[70%] max-sm:max-w-[85%]">
              <h4 className="font-title text-[26px] text-white">
                Evaluate & Elevate
              </h4>
              <p className="font-body">
                Your dog’s health is our number one priority, which is why we
                have built Evaluate and Elevate, our proprietary aftercare
                system. Our aftercare system allows your dog’s formula to adjust
                and change as their healthcare evolves.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="mx-auto h-[360px] w-full bg-dteal-400 bg-exclusive bg-cover bg-center bg-no-repeat lg:h-56 lg:w-56 lg:rounded-full"></div>
            <div className="mx-auto flex max-w-[290px] flex-col gap-2 text-center max-lg:max-w-[70%] max-sm:max-w-[85%]">
              <h4 className="font-title text-[26px] text-white">
                Exclusive Partnerships
              </h4>
              <p className="font-body">
                We’ve partnered with brands who love dogs just as much as we do.
                As a This Dog Is Unique customer, you’ll get invited to special
                promotions, webinars and more!
              </p>
            </div>
          </div>
          {/* {moreSupplement.map((moreItem) => {
            return (
              <div className="flex gap-4 flex-col">
                <div
                  className={`mx-auto lg:rounded-full bg-dteal-400 bg-cover bg-center bg-no-repeat h-[360px] w-full lg:h-56 lg:w-56 bg-${moreItem.bg}`}></div>
                <div className="flex flex-col gap-2 text-center max-w-[290px] mx-auto">
                  <h4 className="text-white font-title text-[26px]">{moreItem.heading}</h4>
                  <p className="font-body">{moreItem.copy}</p>
                </div>
              </div>
            );
          })} */}
        </div>
        <div>
          <a
            href="/quiz/dogName"
            className="mx-auto mt-9 block
                              max-w-fit bg-dteal-400 p-2 px-4
                              font-semibold uppercase text-white md:p-3 md:px-5 md:text-lg">
            Take our quiz
          </a>
        </div>
      </section>
    </>
  );
};

export default Home;