import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GetName from "utils/getName";
import getSi from "utils/getSi";
import updateHealthIssuesAnswers from "utils/updateHealthIssuesAnswers";
import updateQuizInfo, { getQuizInfo } from "utils/updateQuizInfo";
import DotDetails from "../DotDetails/DotDetails";
import DotSelector from "../DotSelector/DotSelector";
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';

const QuizDermatologyThree = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState("");
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.dermatology_q3) {
      setOption(info.dermatology_q3);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({slide: "dermatology-3"});
    } else {
      navigate("/quiz/1");
    }
  }, [si]);
  const [option, setOption] = useState(1);
  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];
  const handleClick = () => {
    if (option) {
      updateQuizInfo({ dermatology_q3: option });
      updateHealthIssuesAnswers({ dermatology_q3: option });
      navigate(`/quiz/dermatology-4/?si=${si}`);
    }
  };

  const dotDetailsData = [
    {
      title: "Not at all",
      body: "My dog sometimes licks their paws but it doesn’t bother them much",
    },
    {
      title: "Somewhat",
      body: "My dog licks their paws quite often",
    },
    {
      title: "Significantly",
      body: "My dog licks their paws almost all the time",
    },
  ];
  return (
     <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-12">
        <QuizSlideHeading before={`Does ${name}`} title="Lick their paws?"/>

        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}

          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation handleBack={()=>navigate(-1)}   handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizDermatologyThree;
