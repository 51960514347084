import AltButton from 'pages/App/AltButton/AltButton';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizAnxietyFour = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.stress_and_anxiety_q4) {
      setOption(info.stress_and_anxiety_q4);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'anxiety-4' });
    } 
  }, [si]);

  const [option, setOption] = useState(1);
  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];

  const dotDetailsData = [
    {
      title: 'Not at all',
      body: 'My dog is never destructive',
    },
    {
      title: 'Somewhat',
      body: 'My dog is sometimes destructive around the house',
    },
    {
      title: 'Significantly',
      body: 'My dog regularly causes damage to the home or surroundings',
    },
  ];

  const handleClick = () => {
    if (option) {
      updateQuizInfo({ stress_and_anxiety_q4: option });
      updateHealthIssuesAnswers({ stress_and_anxiety_q4: option });
      navigate(`/quiz/anxiety-5/?si=${si}`);
    }
  };

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-12">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Show destructive behaviour?"
        />
        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation handleBack={()=>navigate(-1)}  handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizAnxietyFour;
