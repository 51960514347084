import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const Thanks = () => {
  useEffect(() => {
    const paidPrice = JSON.parse(sessionStorage.getItem('chosenPrice'));
    if (paidPrice !== null) {
      ReactPixel.track('Purchase', {currency: "GBP", value: Number(paidPrice)});
    }
    sessionStorage.clear()
  }, []);
  
  return (
    <section className="w-scren flex h-screen flex-col items-center justify-start gap-3 bg-dteal-400 pt-24">
      <Link to={`/`}>
        <img
          src="/img/thank-you-logo.svg"
          className="mb-16 w-64 lg:w-96"
          alt="this dog is unique"
        />
      </Link>

      <h1 className="text-xl text-white underline">Thanks for your order.</h1>
      <h2 className="max-w-xl text-center font-body text-lg text-white">
        Check your emails for your order confirmation. We will be in touch once
        your dog's fully personalised supplement has been carefully manufactured
        in our lab.
      </h2>
    </section>
  );
};

export default Thanks;
