import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizAnxietyOne = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.stress_and_anxiety_q1) {
      setOption(info.stress_and_anxiety_q1);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'anxiety-1' });
      sessionStorage.setItem('current_health_issue', JSON.stringify('anxiety'));
    } 
  }, [si]);
  const [option, setOption] = useState(1);

  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];
  const handleClick = () => {
    if (option) {
      updateQuizInfo({ stress_and_anxiety_q1: option });
      updateHealthIssuesAnswers({ stress_and_anxiety_q1: option });
      navigate(`/quiz/anxiety-2/?si=${si}`);
    }
  };

  const dotDetailsData = [
    {
      title: 'Not at all',
      body: 'My dog is always happy and comfortable around new people',
    },
    {
      title: 'Somewhat',
      body: 'My dog can be nervous or tentative around new people and it takes time to build their trust',
    },
    {
      title: 'Significantly',
      body: 'My dog becomes very stressed around unfamiliar people',
    },
  ];
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-12">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Become stressed around people?"
        />
        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation handleBack={()=>navigate(-1)} handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizAnxietyOne;
