import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Sizes = {
  h1: "H1",
  h1_2: "H1_2",
  h1_3: "H1_3",
  h2: "H2",
  h3: "H3",
}

export const Colors = {
  orange: "orange",
  teal: "teal",
  white: "white",
}

const sizeClasses = {
  [Sizes.h1]: "lg:text-[44px] md:text-[30px] max-md:text-[20px] mb-[32px]",
  [Sizes.h1_2]: "lg:text-[44px] md:text-[30px] max-md:text-[26px] mb-[32px]",
  [Sizes.h1_3]: "lg:text-[44px] md:text-[26px] max-md:text-[26px] mb-[32px]",
  [Sizes.h2]: "text-2xl md:text-4xl lg:text-6xl",
  [Sizes.h3]: "text-xl md:text-3xl lg:text-5xl mb-2 md:mb-8",
}

const colorClasses = {
  [Colors.orange]: "text-dorange-400",
  [Colors.teal]: "text-dteal-400",
  [Colors.white]: "text-white",
}

const Heading = (props) => {
  const color = colorClasses[props.color || Colors.teal];
  const size = sizeClasses[props.size || Sizes.h3];

  const classes = classNames("font-title font-semibold", color, size, props.className);
  return (
    <h2 className={classes}>{props.children}</h2>
  )
}

Heading.propTypes = {
  size: PropTypes.oneOf(Object.values(Sizes)),
  color: PropTypes.oneOf(Object.values(Colors)),
  className: PropTypes.string,
};

export default Heading