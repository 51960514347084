import React, { useState } from "react";
import useWindowDimensions from "utils/useWindowDimensions";

import "./DotSelector.scss";

const DotSelector = ({ data, option, setOption, details }) => {
  const { width } = useWindowDimensions();
  return width > 900 ? (
    <div className="dotselector">
      {data.map((data) => (
        <div
          onClick={() => setOption(data.name)}
          className={`dotselector__dot ${data.name === option ? "active" : ""}`}
        ></div>
      ))}
    </div>
  ) : (
    <div className="boxselector">
      {data.map((data, idx) => (
        <div
          onClick={() => setOption(data.name)}
          className={`boxselector__box ${data.name === option ? "active" : ""}`}
        >
          <div className="boxselector__title">{details[idx].title}</div>
          <div className="boxselector__body">
            {(details[idx].body).replace(/<\/?[^>]+(>|$)/g, "")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DotSelector;
