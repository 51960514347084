import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import nextIssue from 'utils/nextIssue';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizJointCareFive = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState(null);
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'joint-care-5' });
      sessionStorage.setItem(
        'current_health_issue',
        JSON.stringify('joint-care'),
      );
    } 
  }, [si]);
  const [active, setActive] = useState(null);

  const handleClick = (jointsAnswer) => {
    setActive(jointsAnswer);
    jointsAnswer = jointsAnswer?.toUpperCase();
    updateQuizInfo({ joints_q5: jointsAnswer });
    updateHealthIssuesAnswers({
      joints_q5: jointsAnswer === 'YES' ? true : false,
    });
    const storage = JSON.parse(sessionStorage.getItem('health_issues_answers'));
    if (storage?.problem_q1) {
      const answers = JSON.parse(
        sessionStorage.getItem('health_issues_answer'),
      );
      sessionStorage.setItem(
        'health_issues_answers',
        JSON.stringify({
          ...storage,
          ...answers,
        }),
      );
      navigate(`/quiz/creatingSupplement/?si=${si}`);
    } else {
      nextIssue(navigate);
    }
  };
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Have a diagnosed joint or musculoskeletal condition?"
        />

        <div className="flex w-full flex-col  gap-6 md:flex-row md:justify-center">
          <button
            onClick={() => handleClick('yes')}
            className={`bg-white p-3 text-lg font-bold text-dteal-400 ${
              active === 'yes' ? 'active' : ''
            }`}>
            Yes
          </button>
          <button
            onClick={() => handleClick('no')}
            className={`bg-white p-3 text-lg font-bold text-dteal-400 ${
              active === 'no' ? 'active' : ''
            }`}>
            No
          </button>
        </div>
      </div>
    </section>
  );
};

export default QuizJointCareFive;
