import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { ReactComponent as Logo } from '../../../assets/logos/altLogo.svg';
import AltButton from '../AltButton/AltButton';
import { useDispatch, useSelector } from 'react-redux';
import { signInActions } from 'store/signIn-slice';
import './SignIn.scss';
import { useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import API from '../API/API';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(
    'Please make sure to use the same email address you <br /> used to place your order',
  );
  const dispatch = useDispatch();
  const contextEmail = useSelector((state) => state.signIn.email);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const parameters = JSON.stringify({ email: email });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.post(
        `${API()}/send_magic_link`,
        parameters,
        customConfig,
      );
      if (res.status === 200) {
        dispatch(signInActions.changeEmail(email));
        navigate('/sign-in-confirm');
      }
    } catch (error) {
      if (error.message === 'Request failed with status code 412') {
        setError(
          'The user does not exist. Please make sure to use the <br/> same email address you used to place your order.',
        );
      }
    }
  };
  return (
    <div className="signIn">
      <Logo
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/')}
        className="signIn__logo"
      />
      <div className="signIn__para">
        Type in your email address <br /> and we’ll send a link for you to log
        in
      </div>
      <form action="#" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="email"
          placeholder="woof@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p
          style={{
            color: `${
              error ===
              'The user does not exist. Please make sure to use the <br/> same email address you used to place your order.'
                ? 'var(--secondary-color)'
                : ''
            }`,
          }}>
          {HTMLReactParser(error)}
        </p>
        <AltButton type={'submit'}>Send A Magic Link</AltButton>
      </form>
      <div className="mt-4 text-center text-base">
        <div>Looking for 24/7 Vet Access? Click the link below</div>
        <a href="https://pawsquad.com/thisdogisunique" className="mt-1 underline text-dorange-200">
          24/7 Vet Access
        </a>
      </div>
    </div>
  );
};

export default SignIn;
