import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizDigestiveCareOne = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const [option, setOption] = useState(3);
  const [selectedOption, setSelectedOption] = useState('0');

  const si = getSi();
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  // Restore the dogs's name
  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  // Restore the answer value
  useEffect(() => {
    const info = getQuizInfo();
    if (info?.digestion_q1) {
      setOption(info.digestion_q1);
    }
  }, []);

  // Establish current slide
  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'digestive-care-1' });
      sessionStorage.setItem(
        'current_health_issue',
        JSON.stringify('digestive-care'),
      );
    } 
  }, [si]);

  const digestionOptions = [
    {
      label: 'Type 1',
      imgUrl: '/img/quiz/digestion-1/type-1.svg',
      actUrl: '/img/quiz/digestion-1/type-1-active.svg',
      description: 'Separate hard lumps, like nuts (hard to pass)',
      value: 3,
    },
    {
      label: 'Type 2',
      imgUrl: '/img/quiz/digestion-1/type-2.svg',
      actUrl: '/img/quiz/digestion-1/type-2-active.svg',
      description: 'Sausage-shaped but lumpy',
      value: 2,
    },
    {
      label: 'Type 3',
      imgUrl: '/img/quiz/digestion-1/type-3.svg',
      actUrl: '/img/quiz/digestion-1/type-3-active.svg',
      description: 'Like a sausage but with cracks on its surface',
      value: 2,
    },
    {
      label: 'Type 4',
      imgUrl: '/img/quiz/digestion-1/type-4.svg',
      actUrl: '/img/quiz/digestion-1/type-4-active.svg',
      description: 'Like a sausage or snake, smooth and soft',
      value: 1,
    },
    {
      label: 'Type 5',
      imgUrl: '/img/quiz/digestion-1/type-5.svg',
      actUrl: '/img/quiz/digestion-1/type-5-active.svg',
      description: 'Soft blobs with clear-cut edges',
      value: 2,
    },
    {
      label: 'Type 6',
      imgUrl: '/img/quiz/digestion-1/type-6.svg',
      actUrl: '/img/quiz/digestion-1/type-6-active.svg',
      description: 'Fluffy pieces with ragged edges, a mushy stool',
      value: 2,
    },
    {
      label: 'Type 7',
      imgUrl: '/img/quiz/digestion-1/type-7.svg',
      actUrl: '/img/quiz/digestion-1/type-7-active.svg',
      description: 'Watery, no solid pieces, entirely liquid',
      value: 3,
    },
  ];

  const handleClick = (key) => {
    setSelectedOption(key);
    setOption(digestionOptions[key].value);
    updateQuizInfo({ digestion_q1: digestionOptions[key]?.value });
    updateHealthIssuesAnswers({
      digestion_q1: digestionOptions[key]?.value,
    });
  };

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex min-h-screen flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="have 'normal' stools?"
          after="identify their usual consistency"
        />
        <div className="flex max-w-5xl flex-col gap-3 md:flex-row">
          {digestionOptions.map((option, key) => (
            <div
              key={key}
              className="flex flex-row items-center gap-3 rounded-md border-2 border-dteal-100 p-3 md:flex-col md:flex-wrap md:content-baseline md:border-none md:p-0 cursor-pointer"
              onClick={() => handleClick(key)}>
              <div className="w-1/3 md:w-full">
                <img
                  src={selectedOption != key ? option.imgUrl : option.actUrl}
                  alt={option.description}
                  className="w-full  md:h-9"
                />
              </div>
              <div className="w-2/3  md:w-full">
                <h3 className="text-lg md:text-center md:text-xl">
                  {option.label}
                </h3>
                <p className="text-xs md:text-center md:text-sm">
                  {option.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <QuizSlideNavigation
          handleBack={() => navigate(-1)}
          handleNext={() => {
            option && navigate(`/quiz/digestive-care-2/?si=${si}`);
          }}
        />
      </div>
    </section>
  );
};

export default QuizDigestiveCareOne;
