import { createSlice } from "@reduxjs/toolkit";

const signInSlice = createSlice({
  name: "signIn",
  initialState: { email: "" },
  reducers: {
    changeEmail(state, action) {
      state.email = action.payload;
    },
  },
});

export const signInActions = signInSlice.actions;

export default signInSlice;
