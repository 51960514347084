import React, { useState } from "react";

import "./Ingredients.scss";
import IngredientsContainer from "./IngredientsContainer/IngredientsContainer";
import IngredientsModal from "./IngredientsModal/IngredientsModal";

const Ingredients = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal ? <IngredientsModal setModal={setModal} /> : ""}
      <div className="ingredients">
        <div className="ingredients__hero">
          <h1>Our Ingredients</h1>
        </div>
        <div className="ingredients__statement-container">
          <div className="ingredients__statement">
            <h3>Efficient</h3>
            <p>
              Our supplement ingredients are patented and proven, which means
              you can be confident that they’ll do exactly what they claim.
            </p>
          </div>
          <div className="ingredients__statement">
            <h3>Reliable</h3>
            <p>
              We put each of our supplements through a vigorous process of
              analyzing each ingredient for quality, composition, purity and
              safety.
            </p>
          </div>
          <div className="ingredients__statement">
            <h3>Sustainable</h3>
            <p>
              From compostable packaging to a refill system - we're committed to
              constantly evaluating and evolving better practices that minimise
              our environmental footprint.
            </p>
          </div>
        </div>
        <IngredientsContainer setModal={setModal} />
      </div>
    </>
  );
};

export default Ingredients;
