import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo from 'utils/updateQuizInfo';
import SelectButton from 'pages/App/SelectButton/SelectButton';

import API from 'pages/App/API/API';

import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizSelectHealthIssues = () => {
  const [healthIssues, setHealthIssues] = useState([]);
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const si = getSi();
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // checks for session id in quiz_info in session storage -> if none -> obtain from dynamodb. Used for resuming quiz at later date
  useEffect(() => {

    const getPreviousQuiz = async () => {
      const res = await fetch(`${API()}/quiz/${si}`);
      const formJson = await res.json();
      return formJson
    }

    const processAge = (info) => {
      const dobSplit = info.data.dob.split("/");
      const dob = new Date(dobSplit[2], dobSplit[1]-1, dobSplit[0]);

      var started_at_epoch = info.data.created_at;
      var started_at = new Date(0);
      started_at.setUTCSeconds(started_at_epoch);

      var ageYears = started_at.getFullYear() - dob.getFullYear()
      var ageMonths = started_at.getMonth() - dob.getMonth()

      ageYears = (ageYears === 0 ? null: ageYears)
      ageMonths = (ageMonths === 0 ? null: ageMonths)

      return {
        "years": ageYears,
        "months": ageMonths
      }
    }

    (async () => {
      if (si) {
        updateQuizInfo({ slide: 'selectHealthIssues' });
      } 

      const quizInfo = sessionStorage.getItem('quiz_info');
      if (quizInfo.si == null) {
        // get quiz data
        const quiz_data = await getPreviousQuiz();

        // process dob back into years and months
        const dog_age = processAge(quiz_data);

        updateQuizInfo({ 
          dogName: quiz_data.data.dog_name, 
          email: quiz_data.data.email, 
          si: quiz_data.data.session_id,
          sex: quiz_data.data.sex, 
          age: dog_age, 
          breeds:quiz_data.data.breeds, 
          weight:quiz_data.data.weight
        });
      }

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('from')) {
        sessionStorage.setItem('from', JSON.stringify(urlParams.get('from')));
    
        const reqBody = {
          origin: urlParams.get('from'),
        };

        const res = await fetch(`${API()}/post_origin/${si}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });
      }

    })();
  }, [si]);

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };

    sessionStorage.removeItem('health_issues_answers');
    dogName();
  }, [si]);

  const handleClick = async () => {
    try {
      if (healthIssues.length === 0) {
        setOpen(true);
        return;
      }
      setLoader(true);

      const issuesValues = healthIssues.map((issue) => issue.value);
      const issuesReduced = [...new Set(issuesValues)];

      await postQuiz({
        health_issues: issuesReduced,
      });
      sessionStorage.setItem('health_issues', JSON.stringify(issuesReduced));
      sessionStorage.setItem('health_issues_answer', JSON.stringify({}));
      sessionStorage.setItem(
        'current_health_issue',
        JSON.stringify(issuesReduced[0]),
      );
      if (issuesReduced.length > 0) {
        navigate(`/quiz/${issuesReduced[0]}-1/?si=${si}`);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleHealthIssue = (issue) => {
    if (healthIssues.includes(issue)) {
      const list = healthIssues;
      const index = list.indexOf(issue);
      list.splice(index, 1);

      setHealthIssues(list);
    } else {
      const list = healthIssues;

      list.push(issue);
      setHealthIssues(list);
    }
  };

  const healthOptions = [
    {
      value: 'anxiety',
      label: 'Anxiety',
    },
    {
      value: 'dermatology',
      label: 'Allergy',
    },
    {
      value: 'joint-care',
      label: 'Arthritis',
    },
    {
      value: 'dental',
      label: 'Dental Health',
    },
    {
      value: 'anxiety',
      label: 'Disruptive Behaviour',
    },
    {
      value: 'digestive-care',
      label: 'Digestive Issues',
    },
    {
      value: 'heart-health',
      label: 'Heart Health',
    },
    /* {
      value: '',
      label: 'Immunity',
    }, */
    {
      value: 'dermatology',
      label: 'Itching',
    },
    {
      value: 'joint-care',
      label: 'Joint Care',
    },
    {
      value: 'dermatology',
      label: 'Skin & Coat',
    },
    {
      value: 'dental',
      label: 'Smelly Breath',
    },
  ];

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Please select at least one health issue
        </Alert>
      </Snackbar>
      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="container mx-auto flex min-h-screen flex-col items-center justify-center gap-9 px-9 pb-12">
          <QuizSlideHeading
            className="md:max-w-xl"
            title={`${name}’s Health`}
            after="Please select the health areas that you would like to support or alleviate with your dog's unique supplement."
          />

          <div className="mx-auto flex flex-row flex-wrap justify-center gap-2 md:max-w-4xl">
            {healthOptions.map((option) => (
              <div onClick={() => handleHealthIssue(option)}>
                <SelectButton>{option.label}</SelectButton>
              </div>
            ))}
          </div>
          {loader && (
            <div
              style={{
                marginTop: '20px',
                alignSelf: 'center',
                textAlign: 'start',
              }}
              class="dot-falling"></div>
          )}
          <QuizSlideNavigation handleNext={handleClick} />
        </div>
      </section>
    </>
  );
};

export default QuizSelectHealthIssues;
