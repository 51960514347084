import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import API from 'pages/App/API/API';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

import { useDispatch, useSelector } from 'react-redux';

const QuizDogBirth = () => {
  const [months, setInputMonths] = useState(null);
  const [years, setInputYears] = useState(null);

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);

  // handles a reload when Age is already in local storage
  useEffect(() => {
    const info = getQuizInfo();
    if (info?.age) {
      setInputYears(info.age.years);
      setInputMonths(info.age.months);
    }
  }, []);

  // checks for session id in quiz_info in session storage -> if none -> obtain from dynamodb. Used for resuming quiz at later date
  useEffect(() => {

    const getpreviousquiz = async () => {
      const res = await fetch(`${API()}/quiz/${si}`);
      const formJson = await res.json();
      return formJson
    }

    (async () => {
      if (si) {
        updateQuizInfo({ slide: 'dogBirth' });
      } 

      const quizInfo = sessionStorage.getItem('quiz_info');
      if (quizInfo.si == null) {
        const info = await getpreviousquiz();
        updateQuizInfo({ 
          dogName: info.data.dog_name, 
          email: info.data.email, 
          si: info.data.session_id, 
          sex: info.data.sex 
        });
      }

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('from')) {
        sessionStorage.setItem('from', JSON.stringify(urlParams.get('from')));
    
        const reqBody = {
          origin: urlParams.get('from'),
        };

        const res = await fetch(`${API()}/post_origin/${si}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });
      }

    })();
  }, [si]);

  // gets the dog's name from the context
  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  // navigate to next slide
  const handleClick = async () => {

    
    const maxAgeInYears = 30;
    const maxAgeInMonths = maxAgeInYears * 12;
    // check if either the years or months input is greater than zero
    // and check the years in months + the months are under the maxAgeInMonth
    // only then continue on
    if ((years > 0 || months > 0) && years * 12 + months <= maxAgeInMonths) {

      setLoader(false);
      setOpen(false);

      // convert selected years and months age into months
      const ageInMonths = years * 12 + months;

      // get current date
      const currentDate = new Date();

      // substract age in months from current date
      const subtractMonths = (date, months) => {
        date.setMonth(date.getMonth() - months);
        return date;
      };
      const dob = subtractMonths(currentDate, ageInMonths);

      // Split birthday object into year, month and date
      const dobYear = dob.getFullYear();
      const dobMonth = dob.getMonth() + 1; // month + 1 at it counts from 0
      const dobDate = dob.getDate();

      try {
        setLoader(true);
        updateQuizInfo({ age: { years, months } });

        const dateOfBirth = `${dobDate}/${dobMonth}/${dobYear}`;

        await postQuiz({
          dob: dateOfBirth,
          age: 12 * years + months, // age is in months
        });

        setLoader(false);
        navigate(`/quiz/dogSingleBreed/?si=${si}`);
      } catch (error) {
        setLoader(false);
      }
    } else {
      setLoader(true);
      setOpen(true);
    }
  };

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setError(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Ooops, this age doesn't look quite right!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Something went wrong! Try again
        </Alert>
      </Snackbar>
      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="container mx-auto flex h-screen max-w-5xl flex-col items-center justify-center gap-9 px-9">
          <QuizSlideHeading
            title={`${name}'s age is:`}
            after="If you are not sure, please use an approximate age you think matches your dog"
          />
          <div className="flex flex-row gap-6">
            <div className="flex flex-col items-center gap-2 md:flex-row md:gap-3">
              <input
                value={years}
                onChange={(e) => setInputYears(Number(e.target.value))}
                className="w-32 p-1 py-3 text-center text-lg md:w-36 md:px-2 md:text-xl"
                type="number"
                min="0"
                max="30"
                placeholder="-"
                required
              />
              <label className="text-lg font-bold">Year(s)</label>
            </div>
            <div className="flex flex-col items-center gap-2 md:flex-row md:gap-3">
              <input
                value={months}
                onChange={(e) => setInputMonths(Number(e.target.value))}
                className="w-32 p-1 py-3 text-center text-lg md:w-36 md:px-2 md:text-xl"
                type="number"
                min="0"
                max="11"
                placeholder="-"
              />
              <label className="text-lg font-bold">Month(s)</label>
            </div>
          </div>
          <QuizSlideNavigation 
            handleNext={handleClick} />
        </div>
      </section>
    </>
  );
};

export default QuizDogBirth;
