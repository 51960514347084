import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './QuizCreatingSupplement.scss';

import Loader from './Loader.gif';
import LoaderMobile from './loaderMobile.gif';

import API from 'pages/App/API/API';

import useWindowDimensions from 'utils/useWindowDimensions';

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const roundFloatToTwoDigitsAfterPoint = (number) => {
  return Math.floor(number * 100) / 100;
};

const QuizCreatingSupplement = () => {
  const navigate = useNavigate();
  const sessionIds = JSON.parse(sessionStorage.getItem('session_ids'));

  useEffect(() => {
    // Get session ids from session storage

    // GET retrieve_formula_from_db (/retrieve_formula_from_db/{formulaCode})
    /** takes formulaCode in, return object with targetted health concern,
     * iterable ingredients and dosages
     * [ targeting: "", formulation: [{ ingredient: "", dosage: "" }, ... ] ]
     * */
    const getRetrieveFormula = async (code) => {
      const res = await fetch(`${API()}/retrieve_formula_from_db/${code}-30`);
      const formJson = await res.json();

      const ingredients = formJson.data.ingredients;

      const formulation = ingredients.map((form) => {
        const dosage = Object.values(form)[0] / 30;

        return {
          targeting: formJson.data.name,
          ingredient: Object.keys(form)[0],
          dosage: isFloat(dosage) ? dosage.toFixed(2) : Math.round(dosage),
        };
      });

      return formulation;
    };

    // GET generate_price (/generate_price?session_id={sessionId})
    // takes sessionId in, returns price and formulation { price: "", formulation: []}
    const getGeneratePrice = async (si) => {
      const res = await fetch(`${API()}/generate_price?session_id=${si}`);
      const priceJson = await res.json();

      // GET retrieve_formula_from_db
      let formulations = [];
      for (const code of priceJson.data.ingredient_codes) {
        formulations.push(await getRetrieveFormula(code));
      }

      // take all ingredients names from formulations array, and make a call to backend to pull desired ingredient's labels from labels_table

      return {
        price: priceJson.data.price,
        formulation: formulations,
      };
    };

    // get order for a single session
    const getOrder = async (si) => {
      // get the quiz basic from from the session id
      const res = await fetch(`${API()}/quiz/${si}`);
      const quizJson = await res.json();

      // Get the price and formulation
      const generatePrice = await getGeneratePrice(si);

      return {
        sessionId: quizJson.data.session_id,
        dogName: quizJson.data.dog_name,
        price: generatePrice.price,
        formulation: generatePrice.formulation,
      };
    };

    (async () => {
      let orders = [];
      for (const si of sessionIds) {
        try {
          orders.push(await getOrder(si));
        } catch (err) {
          console.error(err, 'Error occurred');
        }
      }
      sessionStorage.setItem('orders', JSON.stringify(orders));
      navigate(`/quiz/ingredients`);
    })();
  }, []);

  const getDogsName = async (si) => {
    const res = await fetch(`${API()}/quiz/${si}`);
    const data = await res.json();
    return data.data.dog_name;
  };

  useEffect(() => {
    (async () => {
      let pricelist = [];
      for (const si of sessionIds) {
        const res = await fetch(`${API()}/generate_price?session_id=${si}`);
        const data = await res.json();

        const dogName = await getDogsName(si);

        const months = 2;
        const discount = 10;
        const vat = 20;

        // 30 day price off API (2799) converted to decimal 27.99
        const basePrice = data.data.price / 100;

        // calculated VAT for base price
        const calcVAT = (basePrice * vat) / 100;

        // base price with VAT
        const price30 = basePrice + calcVAT;

        // 60 day price with VAT
        const price60 = price30 * months;

        // 60 day price discount amount
        const discount60 = (price60 * discount) / 100;

        // 60 day price with discount applied
        const price60discounted = price60 - discount60;

        pricelist.push({
          sessionId: si,
          dogName: dogName,
          price30: roundFloatToTwoDigitsAfterPoint(Number.parseFloat(price30)),
          price60: roundFloatToTwoDigitsAfterPoint(
            Number.parseFloat(price60discounted),
          ),
          discount: roundFloatToTwoDigitsAfterPoint(
            Number.parseFloat(discount60),
          ),
        });
      }
      sessionStorage.setItem('prices', JSON.stringify(pricelist));

      const prices30 = pricelist.map((price) => {
        return Number.parseFloat(price.price30);
      });

      const prices60 = pricelist.map((price) => {
        return Number.parseFloat(price.price60);
      });

      const total30 = prices30.reduce((acc, curr) => acc + curr);
      const total60 = prices60.reduce((acc, curr) => acc + curr);

      sessionStorage.setItem(
        'totals',
        JSON.stringify({
          total30: Number.parseFloat(total30).toFixed(2),
          total60: Number.parseFloat(total60).toFixed(2),
        }),
      );
    })();
  }, [sessionIds]);

  const { width } = useWindowDimensions();

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-6 bg-dteal-50 px-6">
      <h3 className="max-w-xs text-center font-title text-2xl font-bold text-dorange-400 md:max-w-lg md:text-4xl">
        Formulating from millions of different combinations. Yes, it really is
        that high!
      </h3>
      <img src={width < 550 ? LoaderMobile : Loader} alt="" />
    </div>
  );
};

export default QuizCreatingSupplement;
