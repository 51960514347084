import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import QuizJointCareFour from './QuizJointCareFour/QuizJointCareFour';
import QuizHeartHealthOne from './QuizHeartHealthOne/QuizHeartHealthOne';
import QuizDigestiveCareOne from './QuizDigestiveCareOne/QuizDigestiveCareOne';
import QuizDigestiveCareTwo from './QuizDigestiveCareTwo/QuizDigestiveCareTwo';
import QuizDermatologyOne from './QuizDermatologyOne/QuizDermatologyOne';
import QuizDermatologyTwo from './QuizDermatologyTwo/QuizDermatologyTwo';
import QuizDermatologyThree from './QuizDermatologyThree/QuizDermatologyThree';
import QuizDermatologyFour from './QuizDermatologyFour/QuizDermatologyFour';
import QuizDentalOne from './QuizDentalOne/QuizDentalOne';
import QuizDentalTwo from './QuizDentalTwo/QuizDentalTwo';
import QuizDentalThree from './QuizDentalThree/QuizDentalThree';
import QuizAnxietyOne from './QuizAnxietyOne/QuizAnxietyOne';
import QuizAnxietyTwo from './QuizAnxietyTwo/QuizAnxietyTwo';
import QuizAnxietyThree from './QuizAnxietyThree/QuizAnxietyThree';
import QuizAnxietyFour from './QuizAnxietyFour/QuizAnxietyFour';
import QuizAnxietyFive from './QuizAnxietyFive/QuizAnxietyFive';
import QuizJointCareOne from './QuizJointCareOne/QuizJointCareOne';
import QuizJointCareTwo from './QuizJointCareTwo/QuizJointCareTwo';
import QuizJointCareFive from './QuizJointCareFive/QuizJointCareFive';
import QuizDigestiveCareThree from './QuizDigestiveCareThree/QuizDigestiveCareThree';
import QuizJointCareThree from './QuizJointCareThree/QuizJointCareThree';
import QuizDogName from './QuizDogName/QuizDogName';
import QuizEmail from './QuizEmail/QuizEmail';
import QuizConfirmEmail from './QuizConfirmEmail/QuizConfirmEmail';
import QuizDogGender from './QuizDogGender/QuizDogGender';
import QuizDogBirth from './QuizDogBirth/QuizDogBirth';
import QuizDogSingleBreed from './QuizDogSingleBreed/QuizDogSingleBreed';
import QuizDogDoubleBreed from './QuizDogDoubleBreed/QuizDogDoubleBreed';
import QuizDogTripleBreed from './QuizDogTripleBreed/QuizDogTripleBreed';
import QuizDogBreedDetail from './QuizDogBreedDetail/QuizDogBreedDetail';
import QuizDogWeight from './QuizDogWeight/QuizDogWeight';
import QuizDogDetail from './QuizDogDetail/QuizDogDetail';
import QuizSelectHealthIssues from './QuizSelectHealthIssues/QuizSelectHealthIssues';
import QuizCreatingSupplement from './QuizCreatingSupplement/QuizCreatingSupplement';
import { AnimatePresence, motion }   from 'framer-motion';
import QuizSelectPlan from './QuizSelectPlan/QuizSelectPlan';
import QuizIngredients from './QuizIngredients/QuizIngredients';
import QuizHeader from './QuizHeader/QuizHeader';
import QuizAddDog from './QuizAddDog/QuizAddDog';

const quizQuestions = [
  {
    url: 'dogName',
    question: '1',
    quiz: <QuizDogName />,
    navigation: '',
  },
  {
    url: 'email',
    question: '2',
    quiz: <QuizEmail />,
    navigation: '',
  },
  {
    url: 'confirmEmail',
    question: '3',
    quiz: <QuizConfirmEmail />,
    navigation: '',
  },
  {
    url: 'dogGender',
    question: '4',
    quiz: <QuizDogGender />,
    navigation: '',
  },
  {
    url: 'dogBirth',
    question: '5',
    quiz: <QuizDogBirth />,
    navigation: '',
  },
  {
    url: 'dogSingleBreed',
    question: '6',
    quiz: <QuizDogSingleBreed />,
    navigation: '',
  },
  {
    url: 'dogDoubleBreed',
    question: '6',
    quiz: <QuizDogDoubleBreed />,
    navigation: '',
  },
  {
    url: 'dogTripleBreed',
    question: '6',
    quiz: <QuizDogTripleBreed />,
    navigation: '',
  },
  {
    url: 'dogBreedDetail',
    question: '7',
    quiz: <QuizDogBreedDetail />,
    navigation: '',
  },
  {
    url: 'dogWeight',
    question: '8',
    quiz: <QuizDogWeight />,
    navigation: '',
  },
  {
    url: 'dogDetail',
    question: '9',
    quiz: <QuizDogDetail />,
    navigation: '',
  },
  {
    url: 'selectHealthIssues',
    question: '10',
    quiz: <QuizSelectHealthIssues />,
    navigation: '',
  },
  {
    url: 'creatingSupplement',
    question: '13',
    quiz: <QuizCreatingSupplement />,
    navigation: '',
  },
  {
    url: 'joint-care-1',
    question: '-',
    quiz: <QuizJointCareOne />,
    navigation: 'alt',
  },
  {
    url: 'joint-care-2',
    question: '-',
    quiz: <QuizJointCareTwo />,
    navigation: 'alt',
  },
  {
    url: 'joint-care-3',
    question: '-',
    quiz: <QuizJointCareThree />,
    navigation: 'alt',
  },
  {
    url: 'joint-care-4',
    question: '-',
    quiz: <QuizJointCareFour />,
    navigation: 'alt',
  },
  {
    url: 'joint-care-5',
    question: '-',
    quiz: <QuizJointCareFive />,
    navigation: 'alt',
  },
  {
    url: 'heart-health-1',
    question: '-',
    quiz: <QuizHeartHealthOne />,
    navigation: 'alt',
  },
  {
    url: 'digestive-care-1',
    question: '-',
    quiz: <QuizDigestiveCareOne />,
    navigation: 'alt',
  },
  {
    url: 'digestive-care-2',
    question: '-',
    quiz: <QuizDigestiveCareTwo />,
    navigation: 'alt',
  },
  {
    url: 'digestive-care-3',
    question: '-',
    quiz: <QuizDigestiveCareThree />,
    navigation: 'alt',
  },
  {
    url: 'dermatology-1',
    question: '-',
    quiz: <QuizDermatologyOne />,
    navigation: 'alt',
  },
  {
    url: 'dermatology-2',
    question: '-',
    quiz: <QuizDermatologyTwo />,
    navigation: 'alt',
  },
  {
    url: 'dermatology-3',
    question: '-',
    quiz: <QuizDermatologyThree />,
    navigation: 'alt',
  },
  {
    url: 'dermatology-4',
    question: '-',
    quiz: <QuizDermatologyFour />,
    navigation: 'alt',
  },
  {
    url: 'dental-1',
    question: '-',
    quiz: <QuizDentalOne />,
    navigation: 'alt',
  },
  {
    url: 'dental-2',
    question: '-',
    quiz: <QuizDentalTwo />,
    navigation: 'alt',
  },
  {
    url: 'dental-3',
    question: '-',
    quiz: <QuizDentalThree />,
    navigation: 'alt',
  },
  {
    url: 'anxiety-1',
    question: '-',
    quiz: <QuizAnxietyOne />,
    navigation: 'alt',
  },
  {
    url: 'anxiety-2',
    question: '-',
    quiz: <QuizAnxietyTwo />,
    navigation: 'alt',
  },
  {
    url: 'anxiety-3',
    question: '-',
    quiz: <QuizAnxietyThree />,
    navigation: 'alt',
  },
  {
    url: 'anxiety-4',
    question: '-',
    quiz: <QuizAnxietyFour />,
    navigation: 'alt',
  },
  {
    url: 'anxiety-5',
    question: '-',
    quiz: <QuizAnxietyFive />,
    navigation: 'alt',
  },
  {
    url: 'add-dog',
    question: '-',
    quiz: <QuizAddDog />,
    navigation: ''
  },
  {
    url: 'ingredients',
    question: '-',
    quiz: <QuizIngredients />,
    navigation: '',
  },
  {
    url: 'select-plan',
    question: '-',
    quiz: <QuizSelectPlan />,
    navigation: '',
  }
];

const Quiz = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const currentQuestion = quizQuestions.find(({ url }) => url === id);
    setData(currentQuestion);
  }, [id]);

  return (
    data && (
      <>
        <QuizHeader />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          {data.quiz}
        </motion.div>
      </>
    )
  );
};

export default Quiz;
