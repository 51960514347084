import React from 'react';

import Heading, { Colors, Sizes } from '../../../components/typography/Heading';
import Text from '../../../components/typography/Text';

import './PetParents.scss';

const data = {
  header: "We are pet parents who started with a desire to make a better pet supplement.",
  text: "Our team is made up of a unique combination of vets, pet nutritionists, engineers and data scientists. We came together to put an end to reactive care for our pets, and change the tide on their declining health."
}

const PetParents = () => {
  return (
    <section className="h-[32rem] lg:h-[42rem] has-bg-image">
      <div className="container grid-cols-3 content-start md:content-center h-full max-lg:p-[3rem]">
        <div className="col-span-3 md:col-span-2 lg:p-3">
          <Heading size={Sizes.h1}>{data.header}</Heading>
          <Text className="max-w-60p block">{data.text}</Text>
        </div>
      </div>
    </section>
  )
}

export default PetParents