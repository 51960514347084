import React from 'react';
import FooterImg from '../../../assets/images/home-page-footer.png';
import { ReactComponent as Logo } from '../../../assets/logos/logo-white.svg';
import { ReactComponent as InstagramSvg } from '../../../assets/icons/instagram-2.svg';
import { ReactComponent as TickTockSvg } from '../../../assets/icons/tick-tock.svg';
import { ReactComponent as FacebookSvg } from '../../../assets/icons/facebook-f.svg';

import './Footer.scss';
import { Link } from 'react-router-dom';

const copyrightText = '© 2023 This Dog is Unique';

const FooterCopyrights = () => (
  <div className="footer__copyrights text-dteal-100">
    <span className="max-lg:hidden">{copyrightText}</span>
    <div className="min-w-[128px] text-dteal-100">
      <Link to={'/terms-of-service'}>Terms of Service</Link>
      <Link to={'/privacy-policy'}>Privacy Policy</Link>
    </div>
    <span className="min-w-[172px] lg:hidden">{copyrightText}</span>
  </div>
);

const socialLinks = {
  instagram: 'https://www.instagram.com/thisdogisunique/',
  ticktock: 'https://www.tiktok.com/@thisdogisunique',
  facebook: 'https://www.facebook.com/profile.php?id=100089954523675',
};

const Footer = () => {
  const url = new URL(window.location.href);
  const path = url.pathname;

  const isAbout = path === '/about-us';
  const showFooter = !isAbout;

  return (
    <>
      {showFooter && <img src={FooterImg} className="footer__img" alt="" />}
      <footer className="footer">
        <div className="footer__container">
          <Logo />
          <div className="footer__container-details">
            <div className="max-lg:hidden">
              <FooterCopyrights />
            </div>

            <div className="footer__links-box text-dteal-100">
              <Link
                className="footer__container-text text-dteal-100"
                to={'/about-us'}>
                About Us
              </Link>
              <Link
                className="footer__container-text text-dteal-100"
                to={'/quiz/dogName'}>
                Take the Quiz
              </Link>
              <Link
                className="footer__container-text text-dteal-100"
                to={'/faqs'}>
                FAQs
              </Link>
              <Link
                className="footer__container-text text-dteal-100"
                to={'/sign-in'}>
                Sign In
              </Link>
            </div>
            <div className="footer__contact-box">
              <div className="footer__container-text text-dteal-100">
                Contact Us
              </div>
              <div className="text-dteal-100">
                Have a question or want to send us feedback. Just reach out to
                us via email at{' '}
                <a href="/" className="text-dteal-100">
                  care@thisdogisunique.com
                </a>
              </div>

              <div className="mt-4 flex gap-x-1.5">
                <a href={socialLinks.instagram}>
                  <InstagramSvg />
                </a>
                <a href={socialLinks.ticktock}>
                  <TickTockSvg />
                </a>
                <a href={socialLinks.facebook}>
                  <FacebookSvg />
                </a>
              </div>

              <span className="mt-1 text-dteal-100">
                This Pet Group LTD, CC312, The Biscuit Factory, London, SE16 4DG
              </span>
            </div>
          </div>
          <div className="lg:hidden">
            <FooterCopyrights />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
