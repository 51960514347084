import React, { useEffect } from "react";

const MagicLink = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sss = urlParams.get("v");

  useEffect(() => {
    if (sss) {
      const timestamp = +new Date();
      const obj = {
        value: sss,
        timestamp,
      };
      sessionStorage.setItem("sss", JSON.stringify(obj));

      console.log(sessionStorage.getItem("sss"));
      window.location.replace(`https://billing.stripe.com/p/session/${sss}`);
    }
  }, [sss]);

  return <div></div>;
};

export default MagicLink;
