import QuestionBox from "pages/App/QuestionBox/QuestionBox";
import CarouselLoader from "pages/Home/CarouselLoader/CarouselLoader";
import React, { useState } from "react";

import "./Faq.scss";

const supplementQuestions = [
  {
    question: "Will my dog like the taste of their supplement?",
    answer:
      "Our supplements come in a meal topper format and we did extensive testing to ensure that the ingredients we use are palatable and tasty for even the fussiest of dogs.",
  },
  {
    question: "How long will it take to see results?",
    answer:
      "Owing to the custom nature of each and every supplement we produce at This Dog is Unique every supplement will take a different amount of time to produce results. Our Head Vet, Rebecca advises that positives results will generally be seen after 90 days of supplementation - although more oftne than not you'll see results far sooner. Your first order of This Dog is Unique is just the start of a new health journey for your pup. We’re here with you for this journey and the key for seeing results is consistency and making the supplements a part of your dogs daily routine.",
  },
  {
    question: "What ingredients are in my dog’s supplement?",
    answer:
      "The This Dog is Unique team spends hundreds of hours every month reading research and sourcing the very best, clinically proven ingredients. If we were to list every ingredient we have in our database this answer would become a dissertation length post rather than a FAQs response. Once you have completed our quiz and before checking out we show the active ingredients we have specifically picked out just for your pup. All our ingredients are signed off by our Head Vet, Rebecca, and we only choose ingredients with peer reviewed studies behind them - no questionable ingredients here!",
  },
  {
    question: "My dog has allergies - can I use this supplement?",
    answer:
      "This Dog is Unique supplements are designed with dietary sensitivities in mind. That said, we would recommend discussing This Dog is Unique with your vet if your dog has allergies, and please reach out to us at care@thisdogisunique.com so we can make any adjustments where required - and please discuss with your vet.",
  },
  {
    question: "Can This Dog Is Unique be used alongside prescription medications?",
    answer:
      "In short, Yes. Our supplements will not counteract or lead to any health concerns if combined with prescription medication. If you are concerned or need advice then feel free to contact us via care@thisdogisunqiue.com to speak to our care team or alternatively please consult your vet.",
  },
  {
    question: "How and when should I give my dog their supplement?",
    answer:
      "Your first order contains a feeding guide just for you! All you have to do is sprinkle the meal topper using your made to measure spoon on top of your dogs food. Your feeding guide will specify whether that is just for your dog’s breakfast or dinner or both.",
  },
  {
    question: "What is the expiry date of my dog’s supplement?",
    answer:
      // "The expiry date for our supplements is a minimum of 6 months. This Dog is Unique is a subscription service though so nearly all our pet parents will go through their supplement within 30 days or 60 days.",
      "The expiry date for our supplements is a minimum of 6 months. This Dog is Unique is a subscription service though so nearly all our pet parents will go through their supplement within 30 days.",
  },
  {
    question: "Can I retake the quiz?",
    answer:
      "Yes! You can retake the quiz as many times as you’d like. If you’re yet to pay then simply use the back function on the quiz or restart the quiz. If you’ve already paid then simply reach out to us and we can send you a link to redo the quiz if something with your pup has changed.",
  },
  {
    question: "I have more than one dog, what should I do",
    answer: 
      "Once you’ve completed the quiz for your first dog, you’ll be invited to select a plan and pay for your supplement. After you’ve completed the checkout process, you’ll be directed to a page with a button to redo the quiz for another dog!\nClick on the button and redo the quiz, make sure to use the same email address and complete the quiz and check out again! As long as you use the same email address, you’ll see all the supplements for all your whole pack listed on your account when you log in. \nAnd don’t worry, you can still use the same discount code for your other dogs!\nWe are working hard to make it easier and more streamlined for customers with more than one dog to purchase more than one personalised supplement."
  }
];

const subscriptionQuestions = [
  {
    question: "How do I get started?",
    answer:
      `To get started, simply start by entering your dog’s name on the first page of our quiz by clicking the link below: <a href="/quiz/dogName">Take the Quiz</a>`,
  },
  {
    question: "How do I access / edit the details of my subscription?",
    answer:
      `To access your account, go to <a href="thisdogisunique.com">thisdogisunique.com</a> and click on Sign In on the top right corner of the site. Once you’ve clicked on this, you’ll be taken directly to a page where you’ll be asked to enter the email address you used to sign up to This Dog Is Unique. Once you’ve done this, hit the button that says “Send a magic link”. Access to your subscription account is password-less and the magical link will be sent to the email address you entered. Log into that email address and you’ll have an email from care@thisdogisunique with the subject “Magic Log In Link” - open the email and press the “Log In” button and voilá, you’ll be taken straight to your account.`,
  },
  {
    question: "How does my dog’s subscription work?",
    answer:
      // `Once you get to the end of the quiz, you’ll get the option to select either a 30 or 60 day plan:\nIf you select a 30 day plan, you’ll receive precisely 30 days worth of your supplement. You’ll be billed 30 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that!\nIf you select a 60 day plan, you’ll instead receive precisely 60 days worth of your supplement. You’ll be billed 60 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that!`,
      `Once you get to the end of the quiz, you’ll get the option to select a 30 day plan:\nIf you select a 30 day plan, you’ll receive precisely 30 days worth of your supplement. You’ll be billed 30 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that!`,
  },
  {
    question: "What will it cost?",
    answer:
      // `Given our supplements are unique to your dog, the price you pay will be unique to you as well! Typically our supplements cost around £0.85p per day however your price will be calculated based on your dogs unique factors so could be above or below this price. We offer a discount on your first order and you can chose from a 30 and 60 day plan - if you select a 60 day plan, you’ll get an extra 10% off on a per month basis!`,
      `Given our supplements are unique to your dog, the price you pay will be unique to you as well! Typically our supplements cost around £0.85p per day however your price will be calculated based on your dogs unique factors so could be above or below this price. We offer a discount on your first order!`,
  },
  {
    question: "How do I cancel my dog’s subscription?",
    answer:
      `The health and wellbeing of your dog is our number one priority and we will always go above and beyond to ensure that you are happy with your dogs supplement. So if there is something that isn’t working for you or you don’t like, please don’t hesitate to get in touch with us - we will always try to help! However if you do have to cancel, the easiest way to cancel your subscription is by logging into your subscription account. Once you cancel your subscription through your account, you’ll be asked to give a reason why and we’d really appreciate your feedback. Alternatively, you can also email the Care Team at care@thisdogisunique.com to request your subscription be cancelled. Please note this will take 1-2 working days to process.`,
  },
  {
    question: "Can I order This Dog Is Unique without a subscription?",
    answer:
      `In short, no. Our supplements are uniquely tailored to cater for your dogs unique concerns and our supplements provide ongoing support to help them live their best life which is why you can only subscribe. At This Dog is Unique we want to support you and your dog on their health journey, we don’t view ourselves as a short term fix but long term partners helping develop happier and healthier pups.`,
  },
];

const accountQuestions = [
  {
    question: "My email isn't recognised, help!",
    answer:
      `Make sure you try the email you used to create your subscription! If you’re still having problems, be sure to email us at care@thisdogisunique.com and we can help you out.`,
  },
  {
    question: "Oh no, I forgot my password…",
    answer:
      `Oh no, this would be a problem if you needed a password to access your account…but you don’t! Access to your subscription is password-less. To access your account, go to thisdogisunique.com and click on Sign In on the top right corner of the site. Once you’ve clicked on this, you’ll be taken directly to a page where you’ll be asked to enter the email address you used to sign up to This Dog Is Unique. Once you’ve done this, hit the button that says “Send a magic link”. Access to your subscription account is password-less and the magical link will be sent to the email address you entered. Log into that email address and you’ll have an email from care@thisdogisunique with the subject “Magic Log In Link” - open the email and press the “Log In” button and voilá, you’ll be taken straight to your account.`,
  }
];

const paymentsQuestions = [
  {
    question: "When do you take payment?",
    answer:
      // `If you select a 30 day plan, you’ll receive precisely 30 days worth of your supplement. You’ll be billed 30 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that! If you select a 60 day plan, you’ll instead receive precisely 60 days worth of your supplement. You’ll be billed 60 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that!`,
      `With a 30 day plan, you’ll receive precisely 30 days worth of your supplement. You’ll be billed 30 days after you started your subscription and you’ll get your supplement refill pack through the letterbox 10 days after that!`,
  },
  {
    question: "How can I update my payment method?",
    answer:
      `Just login to your account via the Sign In button on the Homepage and then simply update your payment card.`,
  }
];

const discountsQuestions = [
  {
    question: "I have a discount code, where do I add it?",
    answer:
      `You can add your discount code on the final checkout page where you input your address and payment details.`,
  },
  {
    question: "Can I use more than one discount code at a time?",
    answer:
      `You cannot use more than one discount code at a time - if you’re in possession of multiple discount codes then we’d advise using the one with the highest discount!`,
  }
];

const Faq = () => {
  const [tab, setTab] = useState("Supplement");

  const handleChange = (e) => {
    setTab(e.target.innerText);
  };

  return (
    <div className="faq">
      <div className="grid max-w-[1300px]">
        <div className="faq__container">
          <h1 className="font-title text-center font-semibold text-[44px] max-lg:text-[6vw] lg:leading-10 min-sm:leading-[3.5rem]">Frequently Asked Questions</h1>
          <div className="faq__nav-links">
            <div
              onClick={handleChange}
              className={`faq__nav-link ${tab === "Supplement" ? "active" : ""}`}
            >
              Supplement
            </div>
            <div
              onClick={handleChange}
              className={`faq__nav-link ${tab === "Subscription" ? "active" : ""}`}
            >
              Subscription
            </div>
            <div
              onClick={handleChange}
              className={`faq__nav-link ${
                tab === "Account" ? "active" : ""
              }`}
            >
              Account
            </div>
            <div
              onClick={handleChange}
              className={`faq__nav-link ${tab === "Payment" ? "active" : ""}`}
            >
              Payment
            </div>
            <div
              onClick={handleChange}
              className={`faq__nav-link ${
                tab === "Discounts" ? "active" : ""
              }`}
            >
              Discounts
            </div>
          </div>
          <div className="faq__questions-container">
            {/* <div className="faq__questions-title">{tab}</div> */}
            <div className="faq__questions">
              {tab === "Supplement"
                ? supplementQuestions.map((faq) => (
                    <QuestionBox question={faq.question} answer={faq.answer} />
                  ))
                : ""}
              {tab === "Subscription"
                ? subscriptionQuestions.map((faq) => (
                    <QuestionBox question={faq.question} answer={faq.answer} />
                  ))
                : ""}
              {tab === "Account"
                ? accountQuestions.map((faq) => (
                    <QuestionBox question={faq.question} answer={faq.answer} />
                  ))
                : ""}
              {tab === "Payment"
                ? paymentsQuestions.map((faq) => (
                    <QuestionBox question={faq.question} answer={faq.answer} />
                  ))
                : ""}
              {tab === "Discounts"
                ? discountsQuestions.map((faq) => (
                    <QuestionBox question={faq.question} answer={faq.answer} />
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
