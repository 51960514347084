import React from 'react'
import Heading, { Sizes } from '../../../components/typography/Heading';
import Text, { TextFonts, TextSizes } from '../../../components/typography/Text';
import ValueImage from './ValueImage'

import "./ValuePair.scss";

const Container = ({ children }) => (
  <div className="h-[100vw] md:h-[50vw] 2xl:h-[768px] w-full">
    {children}
  </div>
);

const ValuePair = ({ data }) => {
  return (
    <div className={`max-w-[1536px] w-full flex conditional-wrap ${data.isReverse ? " reverse" : ""}`}>
      <Container>
        <div className="w-full h-full flex justify-center items-center text-center p-5 flex-column">
          <Heading className='max-lg:max-w-[150px] min-lg:text-[20px]' size={Sizes.h1_2}>{data.header}</Heading>
          <Text font={TextFonts.fontTitle} size={TextSizes.sm_3} className="block font-title leading-[18px]">{data.text}</Text>
        </div>
      </Container>

      <Container>
        <ValueImage url={data.image.url} background={data.image.background} alt={data.image.alt} />
      </Container>
    </div>
  )
}

export default ValuePair