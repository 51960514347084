import React from 'react';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import API from 'pages/App/API/API';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toWords } from 'number-to-words';
import { useNavigate } from 'react-router-dom';

const QuizAddDog = () => {
  const navigate = useNavigate();

  const si = getSi();

  const [loader, setLoader] = useState(false);
  const [issues, setIssues] = useState(null);
  const [healthIssue, setHealthIssue] = useState(null);
  const [request, setRequest] = useState(false);
  const [dogCount, setDogCount] = useState(1);
  const [currentQuizPosted, setCurrentQuizPosted] = useState(false);
  const [currentSiSaved, setCurrentSiSaved] = useState(false);
  const [addDog, setAddDog] = useState(false);
  const [dogName, setDogName] = useState(['']);

  // Get current dog name
  useEffect(() => {
    setDogName(JSON.parse(sessionStorage.getItem('quiz_info')).dogName);
  }, []);

  // Health issues are generated for the current dog
  useEffect(() => {
    /* if the user has selected add another dog
    don't refetch the quiz responses on the next render */
    const createIsssues = async () => {
      const res = await axios.get(`${API()}/quiz/${si}`);
      const healthIssues = JSON.parse(sessionStorage.getItem('health_issues'));
      setHealthIssue(healthIssues);
      setIssues({
        weight_q1: res.data.data.weight,
        weight_q2: [
          res.data.data.breeds[0] ? res.data.data.breeds[0].id : '',
          res.data.data.breeds[1] ? res.data.data.breeds[1].id : '',
          res.data.data.breeds[2] ? res.data.data.breeds[2].id : '',
        ].filter((e) => e !== ''),
        problem_q1: healthIssues.includes('joint-care') ? 'YES' : 'NO',
        problem_q2: healthIssues.includes('anxiety') ? 'YES' : 'NO',
        problem_q3: healthIssues.includes('digestive-care') ? 'YES' : 'NO',
        problem_q4: healthIssues.includes('dermatology') ? 'YES' : 'NO',
        problem_q5: healthIssues.includes('dental') ? 'YES' : 'NO',
        problem_q6: healthIssues.includes('heart-health') ? 'YES' : 'NO',
      });
    };

    !addDog && createIsssues();
  }, []);

  // Generated health issues are posted to /quiz/{session_id} to complete that particular quiz
  useEffect(() => {
    setLoader(true);
    const postData = async () => {
      try {
        const healthIssuesAnswer = await JSON.parse(
          sessionStorage.getItem('health_issues_answer'),
        );
        await postQuiz({
          ...healthIssuesAnswer,
          ...issues,
        });
        setCurrentQuizPosted(true);
        sessionStorage.setItem(
          'health_issues_answers',
          JSON.stringify({
            ...healthIssuesAnswer,
            ...issues,
          }),
        );
        setLoader(false);
        await setRequest(true);
      } catch (err) {
        setLoader(false);
      }
    };
    if (issues) {
      postData();
    }
  }, [issues]);

  // Once the current SI has been grabbed from the URL...
  useEffect(() => {
    // Getting all session ids currently in session storage
    const storageSi = JSON.parse(sessionStorage.getItem('session_ids'));

    // Check that there are session ids in session storage
    if (storageSi) {
      // Check if the session ids found don't include the current SI already
      if (!storageSi.includes(si)) {
        // Append the current SI to session storage
        if (si !== null) {
          sessionStorage.setItem(
            'session_ids',
            JSON.stringify([...storageSi, si]),
          );
        }
        setCurrentSiSaved(true);
      } else {
        // This means the current Si had already been saved in previous render
        setCurrentSiSaved(true);
      }
    } else {
      sessionStorage.setItem('session_ids', JSON.stringify([si]));
      setCurrentSiSaved(true);
    }
  }, [si]);

  useEffect(() => {
    // check if we haven't set the user email to session storage on a previous render
    if (sessionStorage.getItem('user_email') === null) {
      // Check quiz_info exists in session storage to begin with
      if (sessionStorage.getItem('quiz_info') !== null) {
        const quizInfo = JSON.parse(sessionStorage.getItem('quiz_info'));
        // grab the email from the quiz
        const userEmail = quizInfo.email;
        // set userEmail from quiz to separate user email key in session storage
        sessionStorage.setItem('user_email', JSON.stringify(userEmail));
      }
    }
  }, []);

  useEffect(() => {
    currentSiSaved &&
      setDogCount(JSON.parse(sessionStorage.getItem('session_ids')).length);
  }, [currentSiSaved]);

  // Add another dog clears the session storage except from session_ids and user_email
  const handleAddDog = () => {
    // only remove values from session storage if the current quiz has already been posted
    if (currentQuizPosted) {
      /* set addDog to true to prevent the quiz data being loaded again
      into session storage on the next render */
      setAddDog(true);
      const itemsToRemove = [
        'current_health_issue',
        'health_issues',
        'quiz_info',
        'health_issues_answer',
        'health_issues_answers',
      ];
      itemsToRemove.forEach((item) => {
        sessionStorage.removeItem(item);
      });
      navigate('/quiz/dogName');
    }
  };

  // Continue to checkout
  const handlePreCheckout = () => {
    const itemsToRemove = [
      'current_health_issue',
      'health_issues',
      'quiz_info',
      'health_issues_answer',
      'health_issues_answers',
    ];
    itemsToRemove.forEach((item) => {
      sessionStorage.removeItem(item);
    });
    navigate(`/quiz/creatingSupplement`);
  };

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading
          before={`We're ready to formulate ${dogName}'s supplement!`}
          title="Do you have another dog?"
          after="You can take the quiz again for them, or continue to checkout now"
        />

        <div className="flex flex-col gap-6 lg:flex-row">
          <button
            className="block bg-white p-3 font-button text-xl font-bold uppercase text-dteal-400"
            onClick={() => {
              handleAddDog();
            }}>
            Add another dog
          </button>
          <button
            className="block bg-white p-3 font-button text-xl font-bold uppercase text-dteal-400"
            onClick={() => {
              handlePreCheckout();
            }}>
            {`Continue with ${toWords(dogCount)} ${
              dogCount === 1 ? 'dog' : 'dogs'
            }`}
          </button>
        </div>
      </div>
    </section>
  );
};

export default QuizAddDog;
