import { Alert, Snackbar } from '@mui/material';
import AltButton from 'pages/App/AltButton/AltButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ReactComponent as PlusSvg } from '../../../assets/icons/plus-bg-primary.svg';

import './QuizDogDoubleBreed.scss';
import axios from 'axios';
import API from 'pages/App/API/API';

const QuizDogDoubleBreed = () => {
  const [breeds, setBreeds] = useState(null);
  const [breed1, setBreed1] = useState('');
  const [breed2, setBreed2] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.breeds) {
      info.breeds[0] && setBreed1(info.breeds[0].Breed);
      info.breeds[1] && setBreed2(info.breeds[1].Breed);
    }
  }, []);

  useEffect(() => {
    const getBreed = async () => {
      try {
        const res = await axios.get(`${API()}/breeds`);
        setBreeds(res.data.data);
      } catch (err) {}
    };
    getBreed();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'dogDoubleBreed' });
    } 
  }, [si]);

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      if (breed1 || breed2) {
        setLoader(true);
        updateQuizInfo({
          breeds: [
            breed1 ? breeds.find((e) => e.Breed === breed1) : '',
            breed2 ? breeds.find((e) => e.Breed === breed2) : '',
          ].filter((e) => e !== ''),
        });
        await postQuiz({
          breeds: [
            breed1 ? breeds.find((e) => e.Breed === breed1) : '',
            breed2 ? breeds.find((e) => e.Breed === breed2) : '',
          ].filter((e) => e !== ''),
        });

        setLoader(false);
        navigate(`/quiz/dogBreedDetail/?si=${si}`);
      } else {
        setOpen(true);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Dog breed is required.
        </Alert>
      </Snackbar>
      <div className="quizDoubleBreed quizquestion__container">
        <div className="quizDoubleBreed__question quiz__question-box">
          <div className="quizquestion__title">{name}’s breed is</div>
          <div className="quizquestion__subtitle">
            To your best knowledge, tell us the breeds of your dog.
          </div>
          <form onSubmit={handleClick}>
            <div>
              <div className="breed quiz__select-box">
                <FormControl sx={{ m: 1, minWidth: 420, minHeight: 80 }}>
                  <Select
                    disabled={loader}
                    value={`${breed1 ? breed1 : ''}`}
                    onChange={(event) => setBreed1(event.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {breeds &&
                      breeds.map((e) => (
                        <MenuItem value={e.Breed}>{e.Breed}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <div className="breed quiz__select-box">
                <FormControl sx={{ m: 1, minWidth: 420, minHeight: 80 }}>
                  <Select
                    disabled={loader}
                    value={`${breed2 ? breed2 : ''}`}
                    onChange={(event) => setBreed2(event.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {breeds &&
                      breeds.map((e) => (
                        <MenuItem value={e.Breed}>{e.Breed}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </form>
          {loader && (
            <div
              style={{
                marginTop: '20px',
                alignSelf: 'center',
                textAlign: 'start',
              }}
              class="dot-falling"></div>
          )}
          <div
            className="quiz__different-breed"
            onClick={() =>
              si
                ? navigate(`/quiz/dogTripleBreed/?si=${si}`)
                : navigate(`/quiz/1`)
            }>
            Add another bread <PlusSvg />
          </div>
          <div className="quiz__input-select-btn" onClick={handleClick}>
            <AltButton>Continue</AltButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizDogDoubleBreed;
