import React from 'react'

import AltButton from "../../App/AltButton/AltButton";
import Heading, { Colors, Sizes } from '../../../components/typography/Heading';
import Text from '../../../components/typography/Text';

import step1 from "../../../assets/images/aboutUs/step1.png";
import step2 from "../../../assets/images/aboutUs/step2.png";
import step3 from "../../../assets/images/aboutUs/step3.png";

import "./CallToActionSection.scss";

const data = {
  heading: "How it works",
  steps: [{
    image: {
      url: step1,
      alt: "Finger pointing at quiz",
    },
    name: "Quiz",
    description: "Take our simple online quiz and tell us all about your dog and their unique healthcare needs.",
  }, {
    image: {
      url: step2,
      alt: "Abstract algorithm chart",
    },
    name: "Algorithm",
    description: "Our formulation engine takes your answers and creates the best supplement to match your dog's unique requirements.",
  }, {
    image: {
      url: step3,
      alt: "Database chart",
    },
    name: "Database",
    description: "We store all of our data so that we can continually learn and help inform our veterinary team’s decision making. We analyse all of the dog health data we collect so that we can continually learn and help inform our veterinary team's decision making.",
  }]
}

const Step = ({ name, description, image, index }) => (
  <div className="flex-column items-center gap-5 md:gap-8" style={{ display: "flex" }}>
    <img className="w-24 h-24" src={image.url} alt={image.alt} />
    <div>
      <Heading size={Sizes.h1_3} color={Colors.orange} className="">{index}. {name}</Heading>
      <Text className='md:max-w-[600px]'>{description}</Text>
    </div>
  </div>
)

const CallToActionSection = () => {
  return (
    <section className="call-to-action-section bg-dorange-100 pt-12 px-6 md:py-28">
      <div className="container text-center soft-flex-column gap-8 md:gap-16">
        <Heading color={Colors.orange} className="!mb-0">{data.heading}</Heading>

        <div className="soft-flex-column gap-8 md:gap-16 md:flex-row lg:mx-auto">
          {data.steps.map(({ description, image, name }, key) => (
            <Step description={description} image={image} name={name} key={key} index={key+1} />
          ))}
        </div>

        <a href="/quiz/dogName">
          <AltButton variant="primary" className="block mx-auto">Get Your Formula</AltButton>
        </a>
      </div>
    </section>
  )
}

export default CallToActionSection
