import React from "react";

import "./AltButton.scss";

const AltButton = ({ children, variant, className, type= "text" }) => {
  return (
    <button type={type} className={`altbutton ${variant} ${className}`}>
      {children}
    </button>
  );
};

export default AltButton;
