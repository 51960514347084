import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";

import "./QuestionBox.scss";

const QuestionBox = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`questionbox ${open ? "active" : ""}`}>
      <div className="questionbox__question" onClick={() => setOpen(!open)}>
        {question} <Arrow onClick={() => setOpen(!open)} />
      </div>
      {open && (
        <div
          className="questionbox__answer"
          dangerouslySetInnerHTML={{ __html: answer }}></div>
      )}
    </div>
  );
};

export default QuestionBox;
