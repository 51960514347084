import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import nextIssue from "utils/nextIssue";
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';

import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';

const QuizDentalOne = () => {
  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.dental_q1) {
      setOption(info.dental_q1);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'dental-1' });
      sessionStorage.setItem('current_health_issue', JSON.stringify('dental'));
    } 
  }, [si]);
  const [option, setOption] = useState(1);

  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];
  const handleClick = () => {
    if (option) {
      updateQuizInfo({ dental_q1: option });
      updateHealthIssuesAnswers({ dental_q1: option });
      nextIssue(navigate);
    }
  };

  const dotDetailsData = [
    {
      title: 'Not at all',
      body: 'My dog’s breath is not off putting',
    },
    {
      title: 'Somewhat',
      body: 'My dog’s breath is fairly smelly',
    },
    {
      title: 'Significantly',
      body: 'My dog’s breath smells terrible!',
    },
  ];
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-12">
        <QuizSlideHeading before={`Does ${name}`} title="Have smelly breath?" />
        <div>
          <DotSelector
            data={dotSelectorData}
            setOption={setOption}
            option={option}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation  handleBack={()=>navigate(-1)}  handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizDentalOne;
