import React from 'react'

export const ValueImageBackgrounds = {
  orange: "bg-dorange-400",
  tealLight: "bg-dteal-200",
  tealDark: "bg-dteal-400",
}

const ValueImage = ({ background, url, alt }) => {
  return (
    <div className={`flex justify-center content-end h-full w-full ${background}`}>
      <img src={url} alt={alt} />
    </div>
  )
}

export default ValueImage