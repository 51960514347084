import React, { useState } from "react";
import { ReactComponent as Logo } from "../../../assets/logos/altLogo.svg";
import { ReactComponent as EmailSvg } from "../../../assets/icons/email.svg";

import "./SignInConfirm.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignInConfirm = () => {
  const navigate = useNavigate();
  const contextEmail = useSelector((state) => state.signIn.email);

  return (
    <div className="signIn">
      <Logo
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
        className="signIn__logo confirm"
      />
      <EmailSvg />
      <div className="signInConfirm__title">Check your inbox</div>
      <div className="signInConfirm__para">
        We emailed a magic link to <br />
        <span>{contextEmail}</span>
      </div>
    </div>
  );
};

export default SignInConfirm;
