import React from 'react';
import StatsItem from './StatsItem';

import './StatisticsSection.scss';

const Statistics = [{
  stat: "80%",
  description: "of dogs will develop a dental disease by the age of 3.",
}, {
  stat: "66%",
  description: "increase of dogs with arthritis in the last 10 years.",
}, {
  stat: "1 in 4",
  description: "vet visits are due to skin problems.",
}]

const StatisticsSection = () => {
  return (
    <section className="statistics-section bg-dteal-400 py-12 md:py-28">
      <div className="container flex gap-5 md:gap-10">
        {Statistics.map(({ stat, description }, key) => (
          <StatsItem stat={stat} description={description} key={key} />
        ))}
      </div>
    </section>
  )
}

export default StatisticsSection
