import React from "react";
import { useState } from "react";

import "./IngredientsContainer.scss";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import IngredientImg0 from "../../../assets/images/ingredients/ingredient-0.png";
import IngredientImg1 from "../../../assets/images/ingredients/ingredient-1.png";
import IngredientImg2 from "../../../assets/images/ingredients/ingredient-2.png";
import IngredientImg3 from "../../../assets/images/ingredients/ingredient-3.png";
import IngredientImg4 from "../../../assets/images/ingredients/ingredient-4.png";
import IngredientImg5 from "../../../assets/images/ingredients/ingredient-5.png";
import IngredientImg6 from "../../../assets/images/ingredients/ingredient-0.png";
import IngredientImg7 from "../../../assets/images/ingredients/ingredient-7.png";
import IngredientImg8 from "../../../assets/images/ingredients/ingredient-8.png";
import IngredientImg9 from "../../../assets/images/ingredients/ingredient-9.png";
import IngredientImg10 from "../../../assets/images/ingredients/ingredient-10.png";
import IngredientImg11 from "../../../assets/images/ingredients/ingredient-11.png";
import IngredientImg12 from "../../../assets/images/ingredients/ingredient-12.png";
import IngredientImg13 from "../../../assets/images/ingredients/ingredient-13.png";
import IngredientImg14 from "../../../assets/images/ingredients/ingredient-14.png";
import AltButton from "pages/App/AltButton/AltButton";

const IngredientsContainer = ({ setModal }) => {
  const [name, setName] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const array = [
    IngredientImg0,
    IngredientImg1,
    IngredientImg2,
    IngredientImg3,
    IngredientImg4,
    IngredientImg5,
    IngredientImg6,
    IngredientImg7,
    IngredientImg8,
    IngredientImg9,
    IngredientImg10,
    IngredientImg11,
    IngredientImg12,
    IngredientImg13,
    IngredientImg14,
  ];
  const arraySeeMore = [
    IngredientImg0,
    IngredientImg1,
    IngredientImg2,
    IngredientImg3,
    IngredientImg4,
    IngredientImg5,
    IngredientImg6,
    IngredientImg7,
    IngredientImg8,
    IngredientImg9,
    IngredientImg10,
    IngredientImg11,
    IngredientImg12,
    IngredientImg13,
    IngredientImg14,
    IngredientImg0,
    IngredientImg1,
    IngredientImg2,
    IngredientImg3,
    IngredientImg4,
    IngredientImg5,
    IngredientImg6,
    IngredientImg7,
  ];

  return (
    <div className="ingredientsContainer">
      <div className="ingredientsContainer__form">
        <form className="search">
          <input
            type="text"
            value={name}
            placeholder={"Ingredient Name"}
            onChange={(e) => setName(e.target.value)}
          />
        </form>
        <div
          className="ingredientsContainer__dropdown-box"
          onClick={() => setDropdown(!dropdown)}
        >
          Health Goals <Arrow className={`${dropdown ? "active" : ""}`} />
        </div>
        <div
          className={`ingredientsContainer__dropdown-container ${
            dropdown ? "active" : ""
          }`}
        >
          <form>
            <div className="col-1">
              <label>
                <span className="text">stress and anxiety</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">hair</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">skin problems</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">joints</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">ear disease</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">parasites</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">dental issues</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">obesity</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">urinary tract infections</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">arthritis</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </div>
            <div className="col-2">
              <label>
                <span className="text">stress and anxiety</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">hair</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">skin problems</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">joints</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">ear disease</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">parasites</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">dental issues</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">obesity</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">urinary tract infections</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
              <label>
                <span className="text">arthritis</span>
                <input type="checkbox" />
                <span class="checkmark"></span>
              </label>
            </div>
          </form>
        </div>
      </div>
      <div className="ingredientsContainer__ingredientsList">
        {seeMore
          ? arraySeeMore.map((img, idx) => (
              <div
                className="ingredientsContainer__ingredient-box"
                onClick={() => setModal(true)}
              >
                <img src={img} alt={`${img}`} />
                <div>Alma Fruit</div>
              </div>
            ))
          : array.map((img, idx) => (
              <div
                className="ingredientsContainer__ingredient-box"
                onClick={() => setModal(true)}
              >
                <img src={img} alt={`${img}`} />
                <div>Alma Fruit</div>
              </div>
            ))}
      </div>
      <div onClick={() => setSeeMore(!seeMore)}>
        <AltButton variant="transparent">{!seeMore ? "See More Ingredients" : "See Less Ingredients"}</AltButton>
      </div>
    </div>
  );
};

export default IngredientsContainer;
