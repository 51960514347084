import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { quizActions } from 'store/quiz-slice';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import QuizHeader from '../QuizHeader/QuizHeader';

const QuizDogName = () => {
  const [input, setInput] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.dogName) {
      setInput(info.dogName);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setError(false);
  };

  const handleClick = (e) => {
    setLoader(true);
    e.preventDefault();

    if (input) {
      updateQuizInfo({ dogName: input });
      dispatch(quizActions.changeName(input));
      navigate('/quiz/email');
    } else {
      setLoader(false);
      setOpen(true);
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Dog name is required
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Something went wrong! Try again
        </Alert>
      </Snackbar>

      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="px-9 container mx-auto flex h-screen flex-col items-center justify-center gap-9">
          <QuizSlideHeading before="Please tell us" title="your dog’s name" />
          <input
            className="w-full md:w-96 px-2 py-3 text-center text-xl"
            type="text"
            placeholder="Example: Molly"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <QuizSlideNavigation backHidden handleNext={handleClick} />
        </div>
      </section>
    </>
  );
};

export default QuizDogName;
