import { createSlice } from "@reduxjs/toolkit";

const carouselSlice = createSlice({
  name: "carousel",
  initialState: { currentSlide: 1 },
  reducers: {
    changeSlide(state, action) {
      state.currentSlide = action.payload;
    },
  },
});

export const carouselActions = carouselSlice.actions;

export default carouselSlice;
