import React, { Component } from 'react';
import Footer from 'pages/App/Footer/Footer';
import Header from 'pages/App/Header/Header';
import Home from 'pages/Home';
import Quiz from 'pages/Quiz';
import AboutUs from 'pages/AboutUs';
import NoPage from 'pages/NoPage';
import TermsOfService from 'pages/TermsOfService';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import SignIn from 'pages/App/SignIn';
import { Routes, Route, useLocation } from 'react-router-dom';
import Faq from 'pages/Faq/Faq';
import SignInConfirm from './SignInConfirm/SignInConfirm';
import EmailMagicLink from './EmailMagicLink/EmailMagicLink';
import MagicLink from 'pages/MagicLink/MagicLink';
import Ingredients from 'pages/Ingredients/Ingredients';
import '@stripe/stripe-js';
import TiktokPixel from 'tiktok-pixel';
import ReactPixel from 'react-facebook-pixel';
import { AnimatePresence } from 'framer-motion';
import HandleStripe from 'pages/HandleStripe/HandleStripe';
import ReviewRefine from 'pages/ReviewRefine/ReviewRefine';
import Prelaunch from 'pages/Prelaunch/Prelaunch';
import Thanks from 'pages/Thanks/Thanks';

const App = () => {
  const url = window.location.href;
  const location = useLocation();
  TiktokPixel.init('CFPLHMBC77U6J4U0J8OG')

  const options = {
    autoConfig: true,
    debug: true
  }
  ReactPixel.init('1378111259657036', options)
  
  return (
    <>
      {url.includes('/quiz') |
      url.includes('/sign-in') |
      url.includes('/email') |
      url.includes('/handle_stripe') |
      url.includes('/prelaunch') |
      url.includes('/thanks') |
      url.includes('/magic') ? (
        ''
      ) : (
        <Header />
      )}
      <div className="App">
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route caseSensitive={true} path="quiz/:id" element={<Quiz />} />
            <Route
              caseSensitive={true}
              exact
              path="terms-of-service"
              element={<TermsOfService />}
            />
            <Route
              caseSensitive={true}
              exact
              path="privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              caseSensitive={true}
              exact
              path="about-us"
              element={<AboutUs />}
            />
            <Route
              caseSensitive={true}
              exact
              path="sign-in"
              element={<SignIn />}
            />
            <Route
              caseSensitive={true}
              exact
              path="sign-in-confirm"
              element={<SignInConfirm />}
            />
            <Route
              caseSensitive={true}
              exact
              path="ingredients"
              element={<Ingredients />}
            />
            <Route
              caseSensitive={true}
              exact
              path="email"
              element={<EmailMagicLink />}
            />
            <Route caseSensitive={true} exact path="faqs" element={<Faq />} />
            <Route caseSensitive={true} path="/magic" element={<MagicLink />} />
            <Route
              caseSensitive={true}
              path="/review-refine"
              element={<ReviewRefine />}
            />
            <Route
              caseSensitive={true}
              path="/prelaunch"
              element={<Prelaunch />}
            />
            <Route caseSensitive={true} path="/thanks" element={<Thanks />} />
            <Route
              caseSensitive={true}
              path="/handle_stripe"
              element={<HandleStripe />}
            />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </AnimatePresence>
      </div>

      {url.includes('/quiz') |
      url.includes('/sign-in') |
      url.includes('/email') |
      url.includes('/handle_stripe') |
      url.includes('/prelaunch') |
      url.includes('/magic') ? (
        ''
      ) : (
        <Footer />
      )}
    </>
  );
};

export default App;
