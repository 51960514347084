import React, { useState } from 'react';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import { ReactComponent as TickSvg } from '../../../assets/icons/stylishTick.svg';

import './SelectButton.scss';

const SelectButton = ({ children }) => {
  const [active, setActive] = useState(false);

  return (
    <button
      onClick={() => setActive(!active)}
      className={`h-28 w-28 text-base rounded-full p-3 font-title md:text-xl font-bold transition-colors md:h-32 md:w-32 ${
        active ? 'bg-dteal-400 text-white' : 'bg-dteal-100 text-dteal-400'
      }`}>
      {children}
    </button>
  );
};

export default SelectButton;
