import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HandleStripe = () => {
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.clear();
    navigate("/");
  }, []);
  
  return <div></div>;
};

export default HandleStripe;
