import React from 'react';
import { ReactComponent as StarSvgIcon } from '../../../assets/icons/star.svg';

export default function QuizSelectPlanImageComment({ comment }) {
  return (
    <div className="mt-3 flex flex-col items-center">
      <div className="flex gap-[5px]">
        {new Array(comment.stars).fill(null).map((i, idx) => (
          <StarSvgIcon key={idx} />
        ))}
      </div>
      <div className="mt-3 text-center font-title text-base font-semibold">
        “{comment.text}”
      </div>
      <div className="mt-2.5 text-center text-sm font-semibold">
        {comment.author}, <br />
        Pet Parent to {comment.petName}
      </div>
    </div>
  );
}
