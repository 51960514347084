import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const QuizHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const killQuiz = () => {
    sessionStorage.clear()
    navigate(`/`)
  }

  return (
    <>
      <header
        className={
          location.pathname === '/quiz/ingredients'
            ? `absolute top-0 z-10 w-screen bg-dteal-50 md:bg-transparent`
            : `absolute top-0 z-10 w-screen`
        }>
        <div className="container mx-auto flex flex-row justify-between px-6 py-6 md:px-12">
          <div
            className="h-5 w-6 cursor-pointer bg-close bg-contain bg-no-repeat"
            onClick={() => killQuiz()}></div>
          <div
            className="h-6 w-56 cursor-pointer bg-logo bg-contain bg-no-repeat"
            onClick={() => killQuiz()}></div>
        </div>
      </header>
    </>
  );
};

export default QuizHeader;
