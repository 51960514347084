import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    si: null,
    name: "",
    email: "",
  },
  reducers: {
    changeName(state, action) {
      state.name = action.payload;
    },
    changeEmail(state, action) {
      state.email = action.payload;
    },
  },
});

export const quizActions = quizSlice.actions;

export default quizSlice;
