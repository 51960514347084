import getSi from './getSi';

const nextIssue = (navigate) => {
  const si = getSi();

  const health_issues = JSON.parse(sessionStorage.getItem('health_issues'));
  const current_health_issue = JSON.parse(
    sessionStorage.getItem('current_health_issue'),
  );
  const index = health_issues.indexOf(current_health_issue);
  if (health_issues[index + 1]) {
    sessionStorage.setItem(
      'current_health_issue',
      JSON.stringify(health_issues[index + 1]),
    );
    navigate(`/quiz/${health_issues[index + 1]}-1/?si=${si}`);
  } else {
    navigate(`/quiz/add-dog/?si=${si}`);
  }
};

export default nextIssue;
