import React from 'react';

import DogHealthInDecline from './DogHealthInDecline';
import HeroSection from './HeroSection';
import PetParents from './PetParents';
import StatisticsSection from './StatisticsSection';
import ValuesSection from './ValuesSection';
import BreedsSection from './BreedsSection';
import CallToActionSection from './CallToActionSection';
import PreFooterSection from './PreFooterSection';

const AboutUs = () => {
  return (
    <>
      <HeroSection />
      <PetParents />
      <ValuesSection />
      <DogHealthInDecline />
      <StatisticsSection />
      <BreedsSection />
      <CallToActionSection />
      <PreFooterSection />
    </>
  );
};

export default AboutUs;