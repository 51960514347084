import { configureStore } from "@reduxjs/toolkit";
import carouselSlice from "./carousel-slice";
import quizSlice from "./quiz-slice";
import signInSlice from "./signIn-slice";

const store = configureStore({
  reducer: {
    carousel: carouselSlice.reducer,
    signIn: signInSlice.reducer,
    quiz: quizSlice.reducer,
  },
});

export default store;
