import { Alert, Snackbar } from '@mui/material';
import AltButton from 'pages/App/AltButton/AltButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import axios from 'axios';
import API from 'pages/App/API/API';

import { Combobox } from '@headlessui/react';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

import MultipleBreedsInfoModal from './MultipleBreedsInfoModal/MultipleBreedsInfoModal';

const QuizDogSingleBreed = () => {
  const [modal, setModal] = useState(false);  // new

  const modalText = {  // new
    'cantFindBreed': {
      'header': "I can't find my breed",
      'text': "If you can't find your breed, select the most similar, and be sure to tell us what beautiful breed we've missed, so we can add it to our list!"
    },
    'crossBreed': {
      'header': 'I have a Mixed / Cross breed',
      'text': "For Mixed / Cross breeds, we've included the most popular ones, as well as an option for Mixed / Cross Breeds. In the meantime, if you've selected Mixed / Cross Breed, we'll be in touch by email to ask you a few more questions once you've placed your order."
    }
  }

  const [modalType, setModalType] = useState(modalText[Object.keys(modalText)[0]])  // new

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const si = getSi();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);
  const [open, setOpen] = useState(false);

  const [breeds, setBreeds] = useState([]);
  const [selectedBreed, setSelectedBreed] = useState(breeds[0]);
  const [query, setQuery] = useState('');

  const filteredbreeds =
    query === ''
      ? breeds
      : breeds.filter((breed) => {
          return breed.Breed.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.breeds) {
      setSelectedBreed(info.breeds[0]);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // checks for session id in quiz_info in session storage -> if none -> obtain from dynamodb. Used for resuming quiz at later date
  useEffect(() => {

    const getPreviousQuiz = async () => {
      const res = await fetch(`${API()}/quiz/${si}`);
      const formJson = await res.json();
      return formJson
    }

    const processAge = (info) => {
      const dobSplit = info.data.dob.split("/");
      const dob = new Date(dobSplit[2], dobSplit[1]-1, dobSplit[0]);

      var started_at_epoch = info.data.created_at;
      var started_at = new Date(0);
      started_at.setUTCSeconds(started_at_epoch);

      var ageYears = started_at.getFullYear() - dob.getFullYear()
      var ageMonths = started_at.getMonth() - dob.getMonth()

      ageYears = (ageYears === 0 ? null: ageYears)
      ageMonths = (ageMonths === 0 ? null: ageMonths)

      return {
        "years": ageYears,
        "months": ageMonths
      }
    }

    (async () => {
      if (si) {
        updateQuizInfo({ slide: 'dogSingleBreed' });
      } 

      const quizInfo = sessionStorage.getItem('quiz_info');
      if (quizInfo.si == null) {
        // get quiz data
        const quiz_data = await getPreviousQuiz();

        // process dob back into years and months
        const dog_age = processAge(quiz_data);

        updateQuizInfo({ 
          dogName: quiz_data.data.dog_name, 
          email: quiz_data.data.email, 
          si: quiz_data.data.session_id,
          sex: quiz_data.data.sex, 
          age: dog_age 
        });
      }

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('from')) {
        sessionStorage.setItem('from', JSON.stringify(urlParams.get('from')));
    
        const reqBody = {
          origin: urlParams.get('from'),
        };

        const res = await fetch(`${API()}/post_origin/${si}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });
      }

    })();
  }, [si]);

  // Get breeds
  useEffect(() => {
    const getBreed = async () => {
      try {
        const res = await (await axios.get(`${API()}/breeds`)).data.data;
        setBreeds(res);
      } catch (err) {
        console.error(err, 'getBreed error');
      }
    };
    getBreed();
  }, []);

  // Get dog's name
  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      if (selectedBreed) {
        setLoader(true);
        updateQuizInfo({
          breeds: [breeds.find((e) => e.Breed === selectedBreed)],
        });
        await postQuiz({
          breeds: [breeds.find((e) => e.Breed === selectedBreed)],
        });
        setLoader(false);
        navigate(`/quiz/dogBreedDetail/?si=${si}`);
      } else {
        setOpen(true);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  return (
    <>
      {modal ? <MultipleBreedsInfoModal setModal={setModal} modalType={modalType} /> : ""}
      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="container mx-auto flex h-screen max-w-5xl flex-col items-center justify-center gap-9 px-9">
          <QuizSlideHeading title={`${name}’s breed is:`} />

          <div>
            <Combobox value={selectedBreed} onChange={setSelectedBreed}>
              <Combobox.Input
                placeholder="Ex. Labrador Retriever"
                onChange={(e) => setQuery(e.target.value)}
                className="w-56 px-2 py-3 text-center text-sm md:w-96 md:text-xl"
              />
              <Combobox.Options className="absolute max-h-56 w-56 overflow-y-scroll bg-white px-1 py-3 text-center text-xl shadow-sm md:max-h-96 md:w-96">
                {filteredbreeds.map((breed) => (
                  <Combobox.Option
                    key={breed.id}
                    value={breed.Breed}
                    className="relative cursor-default select-none border-b border-b-dteal-100 py-2 pl-10 pr-4 text-left text-sm text-dteal-400 hover:bg-dteal-50">
                    {breed.Breed}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Combobox>
          </div>
          <QuizSlideNavigation 
            handleNext={(e) => handleClick(e)} />
          <div className="container mx-auto flex max-w-5xl flex-row items-center justify-center gap-10 px-9">
            <button
              className='bg-white py-2 px-3 font-button text-l font-bold text-dteal-300'
              onClick={() => {setModal(true); setModalType(modalText[Object.keys(modalText)[0]])}}>
                I can't find my breed
            </button>
            <button
              className='bg-white py-2 px-3 font-button text-l font-bold text-dteal-300'
              onClick={() => {setModal(true); setModalType(modalText[Object.keys(modalText)[1]])}}>
                I have a cross breed
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuizDogSingleBreed;
