import React, { useState } from "react";

import ReviewRefineBg from "../../assets/images/review-refine-bg.png";
import PersonalizedImg from "../../assets/images/personalized.png";
import ReviewRefineQuiz from "../../assets/images/review-refine-quiz.png";
import { ReactComponent as AddSvg } from "../../assets/icons/add.svg";
import { ReactComponent as MinusSvg } from "../../assets/icons/minus-circle.svg";

import "./ReviewRefine.scss";
import QuestionBox from "pages/App/QuestionBox/QuestionBox";

const ReviewRefine = () => {
  const [activeOne, setActiveOne] = useState(false);
  const [activeTwo, setActiveTwo] = useState(false);
  const [activeThree, setActiveThree] = useState(false);

  const generalQuestions = [
    {
      question: "Lectus gravida cursus mauris vitae elit, et egesta?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
    {
      question: "Pulvinar tincidunt ornare sem fringilla vulputate diam ipsum?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
    {
      question: "Proin enim consectetur diam felis sollicitudin vitae? ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
    {
      question: "Aliquam dolor suspendisse velit gravida nunc purus in?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
    {
      question: "Morbi maecenas mi nisl auctor risus natoque?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
    {
      question: "Sem volutpat aliquam lectus fringilla pharetra ac tellus eget a?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis congue faucibus tristique. Aenean pulvinar eros nec velit rutrum lobortis at quis odio. Mauris erat lacus, volutpat eu sem vel, convallis egestas ligula. Suspendisse nisl massa, sodales vel tortor et, dictum dictum eros. Phasellus ut sem fermentum, lacinia metus id, pharetra tellus. Aenean at ligula augue. Quisque in fermentum nibh, et euismod arcu.",
    },
  ];
  return (
    <div className="reviewRefine">
      <img
        className="reviewRefine__bg"
        src={ReviewRefineBg}
        alt="Review refine hero Img"
      />
      <div className="reviewRefine__personalized">
        <img src={PersonalizedImg} alt=" Review refine personalized img" />
        <div className="reviewRefine__personalized-col">
          <h1>Taking personalisation to a new level</h1>
          <div className="reviewRefine__personalized-box">
            <h3>What it is</h3>
            <p>
              Pupdate asks a series of questions about how your dog’s TDIU
              supplement is performing. From its impact on your dog's energy
              levels, to your dog's hair and skin condition, we want to know it
              all!
            </p>
          </div>
          <div className="reviewRefine__personalized-box">
            <h3>When is it taken?</h3>
            <p>
              Pupdate is open to every customer; once your dog has completed
              their first 60 days on their tailored supplement, you’ll find this
              feature in your account page. If you have any questions, be sure
              to reach out to us at care@TDIU for additional support.
            </p>
          </div>
        </div>
      </div>
      <div className="reviewRefine__pupdate">
        <h1>What kind of formula changes can I expect from Pupdate?</h1>
        <div className="reviewRefine__pupdate-container">
          <div className="reviewRefine__pupdate-col">
            <div className="reviewRefine__pupdate-col-img"></div>
            <div className="reviewRefine__pupdate-col-text">
              On the simplest level, we may tweak the concentration of certain
              ingredients. We may also add or remove individual ingredients. For
              example, maybe you’re not quite seeing the results you were
              expecting with your dog’s joint support? Just say the word, and we
              can safely bump up the level of our joint-care ingredients.
            </div>
          </div>
          <div className="reviewRefine__pupdate-col">
            <div className="reviewRefine__pupdate-col-img"></div>
            <div className="reviewRefine__pupdate-col-text">
              At the final step of Pupdate, we’ll share an overview of the areas
              we’ve improved and where we’ll maintain progress. But if you’re
              ever curious to know exact details, just give our Care Team a
              shout and we’ll provide a full rundown. We’ll include any changes
              in the ingredients list when you receive your refill, so you’ll
              always know exactly what’s in your dog’s TDIU formula.
            </div>
          </div>
        </div>
      </div>
      <div className="reviewRefine__quiz">
        <img src={ReviewRefineQuiz} alt="Review refine quiz img" />
        <div className="reviewRefine__quiz-box">
          <h1>
            Why take our <br /> Review & Refine quiz
          </h1>
          <div className="reviewRefine__quiz-questions-container">
            <div className="reviewRefine__quiz-questions">
              <h3 onClick={() => setActiveOne(!activeOne)}>
                Track you dog's progress {activeOne ? <MinusSvg /> : <AddSvg />}
              </h3>
              {activeOne && (
                <p>
                  Euismod massa arcu tincidunt semper porttitor nec rhoncus
                  metus, egestas. Sed habitant erat fames tellus. Senectus quam
                  interdum neque, quis ligula amet, elit magna amet. In faucibus
                  dictumst id viverra velit. Tellus pharetra auctor augue lectus
                  magna egestas facilisi tellus integer.
                </p>
              )}
            </div>
            <div className="reviewRefine__quiz-questions">
              <h3 onClick={() => setActiveTwo(!activeTwo)}>
                Include up-to-date health metrics
                {activeTwo ? <MinusSvg /> : <AddSvg />}
              </h3>
              {activeTwo && (
                <p>
                  Posuere ipsum odio sodales tempus. Feugiat sed magna elit
                  risus lectus. Felis eros vulputate vestibulum viverra at.
                </p>
              )}
            </div>
            <div className="reviewRefine__quiz-questions">
              <h3 onClick={() => setActiveThree(!activeThree)}>
                Customize and improve your formula
                {activeThree ? <MinusSvg /> : <AddSvg />}
              </h3>
              {activeThree && (
                <p>
                  Posuere ipsum odio sodales tempus. Feugiat sed magna elit
                  risus lectus. Felis eros vulputate vestibulum viverra at.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="reviewRefine__results">
        <h1>What if I still don’t see results?</h1>
        <p>
          For some dogs, we may find that if an issue doesn’t improve even after
          tweaking their formula, they could benefit from support in other ways.
          If this happens, we’ve got your back. We can set up a one to one call
          with our vet, or we can point you in the right direction for other
          specialist tools and support. If we’re at all concerned, we’ll always
          tell you when we think it’s necessary to book your dog in for a check
          up with your own vet, or when to seek emergency treatment.
        </p>
      </div>
      <div className="reviewRefine__faqs">
        <div className="reviewRefine__faqs-box">
          <h1>Frequently Asked Questions</h1>
          <p>
            At TDIU we talk a lot about just how personalised your dog’s
            supplement is. It’s a world first, and we’re really proud to offer
            each dog their own uniquely tailored formula, made to order to
            support their health and wellbeing.
          </p>
          <div className="reviewRefine__faqs-container">
            {generalQuestions.map((faq) => (
              <QuestionBox question={faq.question} answer={faq.answer} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRefine;
