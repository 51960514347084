import axios from "axios";
import API from "pages/App/API/API";
import getSi from "./getSi";


const postQuiz = async (data) => {
    const si = getSi();
  
  try {
    const parameters = JSON.stringify(data);
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${API()}/quiz/${si}`,
      parameters,
      customConfig
    );
  } catch (err) {
    console.log(err);
  }
};

export default postQuiz;
