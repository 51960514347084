import React from 'react';
import { useNavigate } from 'react-router-dom';
import getSi from 'utils/getSi';

const QuizSlideNavigation = (props) => {
  const navigate = useNavigate();

  const si = getSi();
  const url = window.location.href.split('/');

  const backLinks = {
    dogName: '/',
    email: '/quiz/dogName',
    confirmEmail: '/quiz/email',
    dogGender: '/quiz/confirmEmail',
    dogBirth: '/quiz/dogGender',
    dogSingleBreed: '/quiz/dogBirth',
    dogBreedDetail: '/quiz/dogSingleBreed',
    dogWeight: '/quiz/dogBreedDetail',
    dogDetail: '/quiz/dogWeight',
    selectHealthIssues: '/quiz/dogDetail',
    formulatingHealthIssues: '/quiz/selectHealthIssues',
    creatingSupplement: '/quiz/preventIllnesses',
  };

  const link = url[4];

  const handleBack = (e) => {
    e.preventDefault();
    navigate(url[4] === 'dogName' ? '/' : `${backLinks[link]}/?si=${si}`);
  };

  return (
    <div className={`flex flex-row gap-9 ${props.className}`}>
      {!props.backHidden && (
        <button
          className="bg-white py-3  px-4 font-button text-xl font-bold uppercase text-dteal-400"
          onClick={props.handleBack ? props.handleBack : handleBack}>
          {props.backLabel ? props.backLabel : 'Back'}
        </button>
      )}
      {props.handleNext && (
        <button
          onClick={props.handleNext}
          className=" bg-dteal-400 py-3 px-4 font-button text-xl font-bold uppercase text-white">
          {props.nextLabel ? props.nextLabel : 'Next'}
        </button>
      )}
    </div>
  );
};

export default QuizSlideNavigation;
