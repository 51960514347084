import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import './QuizIngredients.scss';

import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';

import { ReactComponent as Arrow} from '../../../assets/icons/arrow.svg';


const QuizIngredients = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setOrders(JSON.parse(sessionStorage.getItem('orders')));
  }, []);

  const navigate = useNavigate();

  return (
    <>
      {/** Two  columns flex */}
      <section className="flex h-screen flex-row bg-dteal-50">
        {/** Left column screen scrollable */}
        <main className="h-screen w-full overflow-y-scroll pb-24 lg:w-1/2">
          {/** Container */}
          <div className="mx-auto flex min-h-screen max-w-lg flex-col items-start justify-center gap-6 py-12 px-6 pt-20">
            <h2 className="font-title text-2xl font-bold text-dorange-400 lg:text-4xl">
              Your Unique plan is ready
            </h2>
            <h3 className="font-title text-lg font-bold leading-normal text-dteal-400 lg:text-xl">
              You can see all the clinically proven ingredients selected by our
              Head Vet which are included in your dog’s unique supplement listed
              below. We’ve grouped and labelled them to show which health area
              they are targeting alongside the unique dose.
            </h3>
            <OrderView orders={orders} />
          </div>
          <footer className="first-letter fixed bottom-0 w-screen bg-dteal-50   lg:w-1/2">
            <div className="container mx-auto flex flex-row justify-center p-6 lg:py-6">
              <QuizSlideNavigation
                backHidden
                nextLabel="Continue to plan selection"
                handleNext={() => {
                  navigate('/quiz/select-plan/');
                }}
              />
            </div>
          </footer>
        </main>

        {/** Right column */}
        <aside className="hidden w-1/2 bg-ordersum bg-cover lg:block"></aside>
      </section>
    </>
  );
};

const OrderView = ({ orders }) => {
  return (
    <>
      {orders.map((order, key) => (
        <>
          <h3 className="font-title text-xl font-bold text-dorange-400 lg:text-3xl">
            {order.dogName}'s supplement
          </h3>
          <FormulationTable formulation={order.formulation} key={key} />
        </>
      ))}
    </>
  );
};

const FormulationTable = ({ formulation }) => {
  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array
  const [expandedRows, setExpandedRows] = useState([]);

  // State variable to keep track which row is currently expanded
  const [expandState, setExpandState] = useState({});

  // This function gets called when row is clicked
  const handleEpandRow = (event, key) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(key);

    let obj = {};
    isRowExpanded ? (obj[key] = false) :  (obj[key] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it
    const newExpandedRows = isRowExpanded ?
          currentExpandedRows.filter(id => id !== key) :
          currentExpandedRows.concat(key);

    setExpandedRows(newExpandedRows);
  }

  const ingredientsDetails = [
    {
      name: 'Glucosamine HCI',
      detail: 'An essential building block in the structure of tendons, cartilage, and ligaments.'
    },
    {
      name: 'Chondroitin Sulphate',
      detail: 'Vital part of cartilage which promotes elasticity and improves shock absorption in the joints.'
    },
    {
      name: 'Green Lipped Mussel',
      detail: 'Rich in naturally occuring omega 3, chondroitin vitamins and minerals to help support healthy joint function.'
    },
    {
      name: 'Omega 3',
      detail: 'Anti-inflammatory which helps tp support and lubricate the joints.'
    },
    {
      name: 'Curcuma Longa',
      detail: 'Antioxidant which helps to defend against inflammation and cellular damage.'
    },
    {
      name: 'Taurine',
      detail: 'Amino acid which can help to support healthy functioning of the heart.'
    },
    {
      name: 'L-Carnitine',
      detail: 'Molecule involved in fatty acid metabolism, thought to aid muscle recovery and support the heart.'
    },
    {
      name: 'Partially Hydrolyzed Guar Gum (SunFiberTM)',
      detail: 'High quality prebiotic "food" for friendly gut bacteria.'
    },
    {
      name: 'EpiCor PetTM (POSTBIOTIC)',
      detail: 'Postbiotic which positively modulates the composition of the gut microbiome.'
    },
    {
      name: 'Vitamin B12 (0.1%)',
      detail: 'Water soluble vitamin which can be deficient in dogs with gastrointestinal problems.'
    },
    {
      name: 'Biotin',
      detail: 'Helps to maintain healthy skin, coat and nails.'
    },
    {
      name: 'Collagen',
      detail: 'Supports skin elasticity and hydration.'
    },
    {
      name: 'Peppermint Oil',
      detail: 'Naturally freshens breath.'
    },
    {
      name: 'Citrus Bioflavanoid',
      detail: 'Antibacterial effect on oral bacteria.'
    },
    {
      name: 'Seaweed',
      detail: 'Inhibits some pathways that contribute to plaque formation.'
    },
    {
      name: 'L-Tryptophan',
      detail: 'Supports serotonin production (the happy hormone).'
    },
    {
      name: 'Lemon Balm (Melissa officinalis)',
      detail: 'Increases GABA activity, a calming neurotransmitter.'
    },
    {
      name: 'Ashwagandha Extract',
      detail: 'Adaptogenic herb which regulates cortisol levels.'
    },
    {
      name: 'Magnolia officinalis',
      detail: "Supports the body's response to daily stressors."
    },
  ]

  function searchIngredientsDetails(name) {
    const ingredient = ingredientsDetails.find(obj => obj.name === name);
    return ingredient ? ingredient.detail : ""
  }

  return (
    <>
      <Table className='formulationTable'>
        <thead>
          <tr>
            <th>Targeting</th>
            <th>Ingredient</th>
            <th>Daily Dosage</th>
          </tr>
        </thead>
        <tbody>
          {formulation.map((formula, rootkey) =>
            formula.map((row, key) => (
              <>
                <tr key={rootkey+key*10} onClick={event => handleEpandRow(event, rootkey+key*10)}>
                  <td className="border-r p-2 font-title font-bold text-dorange-400 lg:p-3">
                    {row.targeting}
                  </td>
                  <td className="border-r p-2 font-title font-bold text-dorange-400 lg:p-3">
                    {row.ingredient}
                  </td>
                  <td className="border-r p-2 font-title font-bold text-dorange-400 lg:p-3">
                    {Number.parseFloat(row.dosage).toFixed(2)}mg
                  </td>
                  <td className="p-2 font-title font-bold text-dorange-400 lg:p-3">
                    <Arrow onClick={event => handleEpandRow(event, rootkey+key*10)} />
                  </td>
                </tr>
                <td colspan="4">
                  <Collapse in={expandedRows.includes(rootkey+key*10)}>
                    <div>
                      { searchIngredientsDetails(row.ingredient) }
                    </div>
                  </Collapse>
                </td>
              </>
            )),
          )}
        </tbody>
      </Table>
    </>
  );
};

export default QuizIngredients;
