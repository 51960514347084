import React from "react";

import "./IngredientsModal.scss";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";

const IngredientsModal = ({ setModal }) => {
  return (
    <div
      className="ingredientsModal"
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          setModal(false)
        }
      }}
    >
      <div className="ingredientsModal__modal">
        <Close onClick={() => setModal(false)} />
        <div className="ingredientsModal__ingredient-name">Alma Fruit</div>
        <div className="ingredientsModal__about">
          <div className="ingredientsModal__heading">About</div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit
            amet blandit justo. Cras lobortis elit in enim tincidunt porta. Duis
            congue faucibus tristique.
          </p>
        </div>
        <div className="ingredientsModal__benefits">
          <div className="ingredientsModal__heading">Key benefits</div>
          <div className="modal__key-benefits">Aenean pulvinar</div>
          <div className="modal__key-benefits">Mauris erat lacus</div>
          <div className="modal__key-benefits">Volutpat eu sem vel</div>
          <div className="modal__key-benefits">Suspendisse nisl massa</div>
        </div>
        <div className="ingredientsModal__research">
          <div className="ingredientsModal__heading">Research</div>
          <ul>
            <li>
              <a href="#">Consectetur adipiscing elit, 2005</a>
            </li>
            <li>
              <a href="#">Duis sit amet blandit justo, 2017</a>
            </li>
            <li>
              <a href="#">Cras lobortis elit in, 2019</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IngredientsModal;
