const API = () => {
  if (process.env.REACT_APP_PROD) {
    //console.log(process.env.REACT_APP_PROD, 'process.env.REACT_APP_PROD');
    return 'https://api-production.thisdogisunique.com';
  } else {
    //console.log(process.env.REACT_APP_PROD, 'process.env.REACT_APP_PROD');
    return 'https://api-dev.thisdogisunique.com';
  }
};

export default API;
