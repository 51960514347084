import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import API from 'pages/App/API/API';

import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';

import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';

const QuizDogWeight = () => {
  const [kilogram, setKilogram] = useState('');

  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.weight) {
      setKilogram(info.weight);
    }
  }, []);

  // checks for session id in quiz_info in session storage -> if none -> obtain from dynamodb. Used for resuming quiz at later date
  useEffect(() => {

    const getPreviousQuiz = async () => {
      const res = await fetch(`${API()}/quiz/${si}`);
      const formJson = await res.json();
      return formJson
    }

    const processAge = (info) => {
      const dobSplit = info.data.dob.split("/");
      const dob = new Date(dobSplit[2], dobSplit[1]-1, dobSplit[0]);

      var started_at_epoch = info.data.created_at;
      var started_at = new Date(0);
      started_at.setUTCSeconds(started_at_epoch);

      var ageYears = started_at.getFullYear() - dob.getFullYear()
      var ageMonths = started_at.getMonth() - dob.getMonth()

      ageYears = (ageYears === 0 ? null: ageYears)
      ageMonths = (ageMonths === 0 ? null: ageMonths)

      return {
        "years": ageYears,
        "months": ageMonths
      }
    }

    (async () => {
      if (si) {
        updateQuizInfo({ slide: 'dogWeight' });
      } 

      const quizInfo = sessionStorage.getItem('quiz_info');
      if (quizInfo.si == null) {
        // get quiz data
        const quiz_data = await getPreviousQuiz();

        // process dob back into years and months
        const dog_age = processAge(quiz_data);

        updateQuizInfo({ 
          dogName: quiz_data.data.dog_name, 
          email: quiz_data.data.email, 
          si: quiz_data.data.session_id,
          sex: quiz_data.data.sex, 
          age: dog_age, 
          breeds:quiz_data.data.breeds 
        });
      }

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('from')) {
        sessionStorage.setItem('from', JSON.stringify(urlParams.get('from')));
    
        const reqBody = {
          origin: urlParams.get('from'),
        };

        const res = await fetch(`${API()}/post_origin/${si}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });
      }

    })();
  }, [si]);

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  const handleClick = async () => {
    try {
      if (kilogram > 0 && kilogram < 126) {
        setLoader(true);
        updateQuizInfo({ weight: kilogram });
        await postQuiz({
          weight: kilogram,
        });
        setLoader(false);
        navigate(`/quiz/dogDetail/?si=${si}`);
      } else {
        setOpen(true);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const updateWeight = (event) => {
    if (event.target.value === 0) {
      setKilogram('');
    } else {
      setKilogram(Math.round(event.target.value));
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Oops, that doesn't look quite right.
        </Alert>
      </Snackbar>
      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
          <QuizSlideHeading title={`${name}’s weight is`} />
          <form style={{ justifyContent: 'center' }}>
            <div>
              <div className="mb-6 flex flex-row items-center gap-2">
                <input
                  min="1"
                  max="125"
                  type="number"
                  placeholder="20"
                  className=" w-48 px-2 py-3 text-center text-xl"
                  onChange={(e) => updateWeight(e)}
                  value={kilogram}
                />
                <label className="text-xl  font-bold">kg</label>
              </div>
            </div>
          </form>
          <QuizSlideNavigation 
            handleNext={handleClick} />
          <p className="max-w-xs text-center text-base">
            Please give your dogs weight to the nearest whole kilogram.
          </p>
        </div>
      </section>
    </>
  );
};

export default QuizDogWeight;
