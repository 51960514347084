import React from 'react';

// eslint-disable-next-line import/no-webpack-loader-syntax
var htmlModule = require('raw-loader!./TermsOfService.html');
var TermsOfServiceHtml = htmlModule.default;

function TermsOfService() {
  return (
    <div className="pt-[130px] pb-[40px] px-[30px] lg:px-[50px]">
      <div
        className="mt-[40px]"
        dangerouslySetInnerHTML={{ __html: TermsOfServiceHtml }}></div>
    </div>
  );
}

export default TermsOfService;
