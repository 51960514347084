import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as SignInDogIcon } from "assets/icons/dog.svg";
import { ReactComponent as Hamburger } from "assets/icons/hamburger.svg";
import { ReactComponent as ThisDogLogo } from "../../../assets/logos/logo.svg";

import "./Header.scss";
import getQuizSlide from "utils/getQuizSlide";

const Header = () => {
  const [active, setActive] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [data, setData] = useState(null);

  const isLessThan24HourAgo = (date) => {
    // 👇️                    hour  min  sec  milliseconds
    const twentyFourHrInMs = 24 * 60 * 60 * 1000;

    const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;

    return date > twentyFourHoursAgo;
  };

  useEffect(() => {
    const getResponse = async () => {
      try {
        if (sessionStorage.getItem("sss")) {
          const response = await JSON.parse(sessionStorage.getItem("sss"));
          setData(response);
          const lessThan24HourAgo = isLessThan24HourAgo(
            new Date(response.timestamp)
          );
          if (lessThan24HourAgo) {
            setLoggedIn(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getResponse();
  }, []);

  return (
    <div className="fixed-top bg-dorange-200 w-100">
      <Container>
        <Row>
          <Col className="text-uppercase pt-3 pb-3 text-center">
            <p className="header-statement">
              GET 25% OFF YOUR FIRST ORDER WITH CODE: WOOF25
            </p>
          </Col>
        </Row>
      </Container>
      <div className="navigation">
        <nav className="header__navbar">
          <div className="navbar__hamburger">
            <Hamburger onClick={() => setActive(!active)} />
          </div>
          <a href={"/"}>
            <ThisDogLogo className="navigation__logo max-[424px]:w-[40vw]" />
          </a>
          <div className="navbar__links-col-2">
            <a href="/about-us">About Us</a>
            <a href="/faqs">FAQs</a>
            <a id="quizButton" href={getQuizSlide()}>
              Take the Quiz
            </a>
            <a
              className="navbar__links__sign-in"
              href={loggedIn ? `/magic?v=${data.value}` : '/sign-in'}>
              <SignInDogIcon />
              {loggedIn ? 'My Orders' : 'Sign in'}
            </a>
            {/* <a href="#">
              <span className="navigation__cart">
                <Cart />
              </span>
              My Basket
            </a> */}
          </div>
        </nav>
      </div>
      <div className={`nav__menubar ${active ? "active" : ""}`}>
        {loggedIn ? (
          <a href={`/magic?v=${data.value}`}>My Orders</a>
        ) : (
          <a href="/sign-in">Sign in</a>
        )}
        <a href="/about-us">About Us</a>
        <a href="/faqs">FAQs</a> 
        <a href={getQuizSlide()}>Take the Quiz</a>
      </div>
    </div>
  );
};

export default Header;
