import React from 'react';

import Heading, { Colors, Sizes } from '../../../components/typography/Heading';

import './HeroSection.scss';

const data = {
  header: "Because every dog deserves the best"
};

const HeroSection = () => {
  return (
    <section className="mt-[7.2rem] h-72 md:h-96 lg:h-[80vh] has-hero-section-bg grid-cols-3 content-center">
      <Heading size={Sizes.h1} color={Colors.orange} className="ml-16 2xl:ml-32 mr-8 col-start-2 col-span-2">{data.header}</Heading>
    </section>
  )
}

export default HeroSection