const updateHealthIssuesAnswers = (answer) => {
  const i = JSON.parse(sessionStorage.getItem("health_issues_answer"));

  let data = {
    ...i,
    ...answer,
  };

  sessionStorage.setItem("health_issues_answer", JSON.stringify(data));
};

export default updateHealthIssuesAnswers;
