import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { quizActions } from 'store/quiz-slice';
import { useNavigate } from 'react-router-dom';
import context from 'react-bootstrap/esm/AccordionContext';
import { Alert, Snackbar } from '@mui/material';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import API from 'pages/App/API/API';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizEmail = () => {
  const [input, setInput] = useState('');
  const [emailLoaded, setEmailLoaded] = useState(false);
  const contextName = useSelector((state) => state.quiz.name);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.email) {
      setInput(info.email);
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
    setOpen2(false);
  };

  const startQuiz = async () => {
    try {
      setLoader(true);
      updateQuizInfo({ email: input });
      let name = contextName[0].toUpperCase() + contextName.substring(1);
      dispatch(quizActions.changeName(name));
      const parameters = JSON.stringify({
        dog_name: name,
        email: input,
      });
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post(
        `${API()}/start_quiz`,
        parameters,
        customConfig,
      );

      updateQuizInfo({
        si: res.data.data.session_id,
        slide: 'email',
        dogName: name,
      });

      setLoader(false);
      navigate(`/quiz/confirmEmail/?si=${res.data.data.session_id}`);
    } catch (error) {
      setLoader(false);
      setOpen2(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (input) {
      startQuiz();
    } else {
      setOpen1(true);
    }
  };

  useEffect(() => {
    // Check if the user email has been previously set in session storage (multi dog)
    if (sessionStorage.getItem('user_email') !== null) {
      const userEmail = JSON.parse(sessionStorage.getItem('user_email'));
      // disable the input field
      setLoader(true);
      // set the value from session storage as the new input value
      setInput(userEmail);
      setEmailLoaded(true);
    }
  }, []);

  /* CURSED - Skip the email slide automatically,
  this might be confusing for the user, the might think
  they accidentally skipped a question */
  useEffect(() => {
    emailLoaded && startQuiz();
  }, [emailLoaded]);

  return (
    <>
      <Snackbar
        open={open1}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Email not recognised, please try a different email
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Email not recognised, please try a different email
        </Alert>
      </Snackbar>

      <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
        <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
          <QuizSlideHeading before="Please tell us" title="your email" />
          <input
            className="w-full px-2 py-3 text-center text-xl md:w-96"
            type="email"
            placeholder="unique@email.com"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            disabled={loader}
          />

          <QuizSlideNavigation handleNext={(e) => handleClick(e)} />
          <p className="max-w-xs text-center text-xs">
            By entering your email and clicking ‘next’ you are consenting to our
            terms of service and privacy policy and consent to This Dog is
            Unique sending you material relating to your formulation and order
          </p>
        </div>
      </section>
    </>
  );
};

export default QuizEmail;
