import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import postQuiz from 'utils/postQuiz';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';

import API from 'pages/App/API/API';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizDogGender = () => {
  const navigate = useNavigate();
  const si = getSi();
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);
  const [loader, setLoader] = useState(false);
  const [dogGender, setDogGender] = useState(null);

  // checks for session id in quiz_info in session storage -> if none -> obtain from dynamodb. Used for resuming quiz at later date
  useEffect(() => {

    const getpreviousquiz = async () => {
      const res = await fetch(`${API()}/quiz/${si}`);
      const formJson = await res.json();
      return formJson
    }

    (async () => {
      console.log(`${API()}`);
      if (si) {
        updateQuizInfo({ slide: 'dogGender' });
      } 

      const quizInfo = sessionStorage.getItem('quiz_info');
      if (quizInfo.si == null) {
        const info = await getpreviousquiz();
        updateQuizInfo({ 
          dogName: info.data.dog_name, 
          email: info.data.email, 
          si: info.data.session_id
        });
      }

      // move into its own useEffect
      // abstract the useEffect created below and the one it sits within into a function stored in utils - for checking resuming quiz and posting orign to quizzes tables
      // if url parameter contains a 'from' parameter, store this locally
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('from')) {
        sessionStorage.setItem('from', JSON.stringify(urlParams.get('from')));
    
        const reqBody = {
          origin: urlParams.get('from'),
        };

        const res = await fetch(`${API()}/post_origin/${si}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });
      }


    })();
  }, [si]);

  // gets the dog's name from the context
  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  // navigate to next slide
  const handleClick = async () => {
    try {
      if (dogGender) {
        setLoader(true);
        updateQuizInfo({ sex: dogGender });
        await postQuiz({ sex: dogGender });
        setLoader(false);
        navigate(`/quiz/dogBirth/?si=${si}`);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading title={`Is ${name} a boy or a girl?`} />
        <div className="flex flex-row items-baseline gap-16">
          <div onClick={() => setDogGender(1)}>
            <button className="flex flex-col items-center gap-3">
              <img
                src={
                  !dogGender || dogGender !== 1
                    ? '/img/dogGender-girl.svg'
                    : '/img/dogGender-girl-selected.svg'
                }
                alt="Girl dog gender"
                className="h-16 w-16"
              />
              <p>Girl</p>
            </button>
          </div>
          <div onClick={() => setDogGender(2)}>
            <button className="flex flex-col items-center gap-3">
              <img
                src={
                  !dogGender || dogGender !== 2
                    ? '/img/dogGender-boy.svg'
                    : '/img/dogGender-boy-selected.svg'
                }
                alt="Boy dog gender"
                className="h-16 w-16"
              />
              <p>Boy</p>
            </button>
          </div>
        </div>
        <QuizSlideNavigation
          handleNext={handleClick}
        />
      </div>
    </section>
  );
};

export default QuizDogGender;
