import React from 'react'

import ValuePair from './ValuePair';
import { ValueImageBackgrounds } from './ValueImage';

import DogCardImg1 from "../../../assets/images/aboutUs/valuesDog1.png";
import DogCardImg2 from "../../../assets/images/aboutUs/valuesDog2.png";
import DogCardImg3 from "../../../assets/images/aboutUs/valuesDog3.png";
import DogCardImg4 from "../../../assets/images/aboutUs/valuesDog4.png";

const FirstPair = {
  header: "Better Ingredients",
  text: "We only select the best ingredients available which are supported by clear, evidence-based research. We put quality and results over everything else and we are continually refining and reviewing the ingredients we use. We go above and beyond to get the best, while leading the way with innovation – that’s why we use ingredients like EpiCor Pets<sup>TM</sup> – leaders in postbiotics.",
  image: {
    url: DogCardImg1,
    background: ValueImageBackgrounds.orange,
    alt: "Photo of a dog",
  },
}

const SecondPair = {
  header: "Better Value",
  text: "With This Dog Is Unique, pet parents get one unique supplement with the best ingredients to support a wide range of health conditions for their furry friends. Our bespoke supplements mean pet parents only need to buy one supplement instead of several, saving them money and time.",
  isReverse: true,
  image: {
    url: DogCardImg2,
    background: ValueImageBackgrounds.tealLight,
    alt: "Photo of a dog",
  },
}

const ThirdPair = {
  header: "Better Aftercare",
  text: "This Dog Is Unique’s “Evaluate and Elevate” aftercare has been designed and developed by our veterinary team with your dog’s long-term health in mind. Our dedicated aftercare team can help adjust your dog’s unique supplement to meet your furry friend’s ongoing needs.",
  image: {
    url: DogCardImg3,
    background: ValueImageBackgrounds.tealDark,
    alt: "Photo of a dog",
  },
}

const FourthPair = {
  header: "More Sustainable",
  text: "We’ve put sustainability at the heart of This Dog Is Unique. We want to eradicate single use plastic – so we don’t use any. Instead, we send our refills in home compostable pouches for you to refill your dog’s customised tin!",
  isReverse: true,
  image: {
    url: DogCardImg4,
    background: ValueImageBackgrounds.orange,
    alt: "Photo of a dog",
  },
}

const ValuesSection = () => {
  return (
    <section className="bg-dteal-50 flex flex-column items-center justify-center">
      <ValuePair data={FirstPair} />
      <ValuePair data={SecondPair} />
      <ValuePair data={ThirdPair} />
      <ValuePair data={FourthPair} />
    </section>
  )
}

export default ValuesSection