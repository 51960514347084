import HTMLReactParser from "html-react-parser";
import React from "react";
// import { Parser } from "webpack";

import "./DotDetails.scss";

const DotDetails = ({ details }) => {
  return (
    <div className="dotdetails">
      {details.map((d) => (
        <div className="dotdetails__details">
          <div className="dotdetails__title">{d.title}</div>
          <div className="dotdetails__body">{HTMLReactParser(d.body)}</div>
          
        </div>
      ))}
    </div>
  );
};

export default DotDetails;
