const getQuizSlide = () => {
  try {
    if (JSON.parse(sessionStorage.getItem("quiz_info")).slide) {
      const info = JSON.parse(sessionStorage.getItem("quiz_info"));
      return `/quiz/${info.slide}/?si=${info.si}`;
    } else {
      return `/quiz/dogName`;
    }
  } catch (err) {
    console.log(err);
    return `/quiz/dogName`;
  }
};

export default getQuizSlide;

//---For prelaunch returning prelaunch page url rather than quiz slide url.

// const getQuizSlide = () => {
//   return `/prelaunch`;
// };

// export default getQuizSlide;
