import React from 'react'

import PreFooter from "../../../assets/images/aboutUs/PreFooter.png";

const PreFooterSection = () => {
  return (
    <section className="relative overflow-hidden">
      <div className="h-40 md:h-60 bg-dorange-100"></div>
      <div className="h-40 md:h-60 bg-dteal-400"></div>
      <img src={PreFooter} className="absolute h-auto w-full md:max-w-3xl max-w-sm bottom-0 left-[50%] origin-center scale-150 md:scale-100 -translate-x-1/2 translate-y-1/4" object-fit="cover" />
    </section>
  )
}

export default PreFooterSection
