import React from 'react';

// eslint-disable-next-line import/no-webpack-loader-syntax
var htmlModule = require('raw-loader!./policy.html');
var PrivacyPolicyHtml = htmlModule.default;

function PrivacyPolicy() {
  return (
    <div className="pt-[180px] pb-[40px] px-[30px] lg:px-[50px]">
      <h1 className="min-sm:leading-[3.5rem] text-center font-title text-[44px] font-semibold max-lg:text-[6vw] lg:leading-10">
        Read the PrivacyPolicy about our personalised dog health service
      </h1>
      <div
        className="mt-[40px]"
        dangerouslySetInnerHTML={{ __html: PrivacyPolicyHtml }}></div>
    </div>
  );
}

export default PrivacyPolicy;
