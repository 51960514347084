/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import EmailMagicLinkImage from "../../../assets/images/emailMagicLink.png";
import { ReactComponent as Logo } from "../../../assets/logos/altLogo.svg";
import AltButton from "../AltButton/AltButton";

import "./EmailMagicLink.scss";

const EmailMagicLink = () => {
  return (
    <div className="emailMagicLink">
      <Logo />
      <img src={EmailMagicLinkImage} alt="Email Magic Link Image" />
      <div className="emailMagicLink__title">Your Link Is Here!</div>
      <p>
        You asked us to send you a magic link for quickly signing in to your
        account at This Dog is Unique.
      </p>
      <AltButton>Sign In Now</AltButton>
      <div className="emailMagicLink__text">
        Or copy and paste this link into your browser: <br />
        <a href="#">http://thisisalink.com/abcd/abc</a>
      </div>
    </div>
  );
};

export default EmailMagicLink;
