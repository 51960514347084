import axios from "axios";
import API from "pages/App/API/API";
import { quizActions } from "store/quiz-slice";

const GetName = async (si, dispatch) => {
  try {
    const res = await axios.get(`${API()}/quiz/${si}`);
    const name = await res.data.data.dog_name;
    dispatch(quizActions.changeName(name));
    return name;
  } catch (err) {
    console.log(err);
  }
};

export default GetName;
