import React, { useContext, useEffect, useState } from 'react';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { ReactComponent as LongArrowLeft } from '../../../assets/icons/long-arrow-left.svg';
import { ReactComponent as LongArrowRight } from '../../../assets/icons/long-arrow-right.svg';
import QuizSelectPlanImageComment from './QuizSelectPlanImageComment';

export default function QuizSelectPlanSlider({ images, comments }) {
  const [currentSlide, setCurrentSlie] = useState(0);

  return (
    <>
      <CarouselProvider
        className="mt-0"
        naturalSlideWidth={345}
        naturalSlideHeight={345}
        totalSlides={images.length}>
        <Slider>
          {images.map((image, idx) => (
            <Slide key={image} index={idx} onFocus={() => console.log('focus')}>
              <img src={image} alt={`slide-${idx}`} className="w-full" />
            </Slide>
          ))}
        </Slider>
        <div className="absolute bottom-0 flex gap-[2px]">
          <ButtonBack className="flex h-[44px] w-[44px] items-center justify-center bg-dteal-400">
            <LongArrowLeft className="h-[15px] w-[15px]" />
          </ButtonBack>
          <ButtonNext className="flex h-[44px] w-[44px] items-center justify-center bg-dteal-400">
            <LongArrowRight className="h-[15px] w-[15px]" />
          </ButtonNext>
        </div>
        <SliderContextUserComponent onSlideChange={(i) => setCurrentSlie(i)} />
      </CarouselProvider>
      {comments?.[currentSlide] && (
        <QuizSelectPlanImageComment comment={comments[currentSlide]} />
      )}
    </>
  );
}

function SliderContextUserComponent({ onSlideChange }) {
  const carouselContext = useContext(CarouselContext);
  useEffect(() => {
    function onChange() {
      onSlideChange(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return <span />;
}
