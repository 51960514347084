import React from 'react';

import Heading, { Colors, Sizes } from '../../../components/typography/Heading';
import Text, { TextSizes, TextWeights } from '../../../components/typography/Text';

import './DogHealthInDecline.scss';

const data = {
  header: "Dog health is in decline",
  text: "Regrettably, dog health is in decline and the industry is still taking a reactive approach. We believe in being proactive with your dog's healthcare by using new technologies and better research to help them live their best life. ",
};

const DogHealthInDeclineSection = () => {
  return (
    <section className="h-72 max-[768px]:h-[570px] max-[768px]:text-center md:h-[26rem] lg:h-[80vh] dog-health-section has-hero-section-bg">
      <div className="container grid-cols-3 content-center">
        <div className="mr-16 max-[768px]:mt-[46px] 2xl:ml-32 ml-8 col-span-2 max-w-[574px]">
          <Heading size={Sizes.h1} color={Colors.white}>{data.header}</Heading>
          <Text weight={TextWeights.semibold} size={TextSizes.sm_3} className="leading-[18px] text-white">{data.text}</Text>
        </div>
      </div>
    </section>
  )
}

export default DogHealthInDeclineSection