import classNames from 'classnames';
import Text, { TextFonts, TextWeights } from 'components/typography/Text';
import React from 'react';

export default function QuizSelectPlanItem({
  labelTop,
  plan,
  tag,  // use tag to decide if stuff below horizontal line should show
  length,
  price,
  oldPrice,
  pricePerDay,
  benefits,
  chosen,
  onClick,
}) {
  return (
    <div className="align-start flex cursor-pointer gap-2 border-[2px] border-black px-4 py-2" onClick={onClick}>
      <span
        className={classNames(
          'mt-[4px] block h-[27px] w-[27px] cursor-pointer rounded-full border-4 border-dteal-400',
          chosen ? 'bg-dorange-400' : 'bg-dteal-400',
        )}
      />
      {/* <div className='flex flex-row justify-between w-full'> */}
        <div className="flex flex-col gap-2.5 w-full">
          <div className="flex items-center gap-2">
            <span className="font-title text-lg font-semibold">{labelTop}</span>
          </div>

          <div className="flex flex-row justify-between items-center w-full">
            {/* {oldPrice && (
              <span className="font-title text-lg font-semibold line-through opacity-50">
                £{oldPrice}
              </span>
            )}
            {tag && (
              <span className="block h-fit bg-dteal-400 px-[4px] py-[2px] font-title text-[8px] font-semibold text-white">
                {tag}
              </span>
            )} */}
            <div className="flex flex-row justify-start gap-2">
              {oldPrice && (
                <div className="font-title text-base font-semibold line-through opacity-50">£{oldPrice}</div>
              )}
              <div className="font-title text-base font-semibold">£{price}</div>
            </div>
            
            <div className="font-title text-sm font-semibold opacity-50 justify-self-end">£{pricePerDay}/serving</div>
          </div>

          {tag==='subscription' && chosen===true && (
            <div>
              <hr />

              <div className="flex flex-row justify-start items-center w-full">
                <div className="text-base font-semibold">Delivery Every:   30 days</div>
              </div>
              
              <ul className="ml-6 list-disc">
                {benefits.map((item, idx) => (
                  <li key={idx}>
                    <Text
                      className="text-[11px]">
                      {item}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* <div className="flex flex-col justify-center items-center gap-1">
          <div className="font-title text-lg font-semibold">£{price}</div>
          {oldPrice && (
            <div className="font-title text-sm font-semibold line-through opacity-50">£{oldPrice}</div>
          )}
        </div> */}
      {/* </div> */}
    </div>
  );
}
