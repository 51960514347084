const updateQuizInfo = (i) => {
  const info = JSON.parse(sessionStorage.getItem("quiz_info"));
  const obj = {
    ...info ,
    ...i ,
  };
  sessionStorage.setItem("quiz_info", JSON.stringify(obj));
};

export default updateQuizInfo;

export const getQuizInfo = () => {
  try {
    const res = sessionStorage.getItem("quiz_info");
    return JSON.parse(res);
  } catch (err) {
    console.log(err);
  }
};
