import React from 'react';

const QuizSlideHeading = (props) => {
  return (
    <div className={`flex flex-col gap-2 ${props.className}`}>
      {props.before && (
        <p className="text-lg text-center font-body md:text-xl">{props.before}</p>
      )}

      {props.title && (
        <h2 className="text-2xl text-center font-title md:text-4xl font-semibold text-dorange-400">
          {props.title}
        </h2>
      )}

      {props.titleQuote && (
        <h2 className="text-2xl text-center font-title md:leading-10 md:text-[2.2rem] font-semibold text-dorange-400">
          <span className='text-dteal-400'>“{props.titleQuote.text}”</span>
          {props.titleQuote.author && (' - ' + props.titleQuote.author)}
        </h2>
      )}

      {props.after && (
        <p className="text-lg text-center font-body md:text-xl">{props.after}</p>
      )}

    </div>
  );
};

export default QuizSlideHeading;