import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from 'pages/App/API/API';
import TiktokPixel from 'tiktok-pixel';

import QuizSelectPlanSlider from './QuizSelectPlanSlider';
import Heading, { Sizes } from 'components/typography/Heading';
import Text, { TextFonts, TextWeights } from 'components/typography/Text';
import QuizSelectPlanItem from './QuizSelectPlanItem';

import { ReactComponent as TickSvgIcon } from '../../../assets/icons/tick.svg';

import SliderImage1 from '../../../assets/images/plan-selection/plan-selection-slider-image-1.jpg';
import SliderImage2 from '../../../assets/images/plan-selection/plan-selection-slider-image-2.jpg';
import SliderImage3 from '../../../assets/images/plan-selection/plan-selection-slider-image-3.jpg';
import SliderImage4 from '../../../assets/images/plan-selection/plan-selection-slider-image-4.jpg';
import SliderImage5 from '../../../assets/images/plan-selection/plan-selection-slider-image-5.jpg';
import SliderImage6 from '../../../assets/images/plan-selection/plan-selection-slider-image-6.jpg';
import SliderImage7 from '../../../assets/images/plan-selection/plan-selection-slider-image-7.jpg';
import QuizSelectPlanImageComment from './QuizSelectPlanImageComment';

function calculatePricesSum(arr, field) {
  return arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue[field],
    0,
  );
}

const QuizSelectPlan = () => {
  const [sessionIds, setSessionIds] = useState([]);
  // const [productIds, setProductIds] = useState({'30': [], '60': []});
  const prices = JSON.parse(sessionStorage.getItem('prices'));

  const sumPrice30 = +calculatePricesSum(prices, 'price30').toFixed(2);
  // const sumPrice60 = +calculatePricesSum(prices, 'price60').toFixed(2);
  // const sumDiscount = +calculatePricesSum(prices, 'discount');

  const [planVariables, setPlanVariables] = useState({'tag': 'subscription', 'length': '30'})

  useEffect(() => {
    const storageSi = JSON.parse(sessionStorage.getItem('session_ids'));
    !storageSi ? setSessionIds([]) : setSessionIds(storageSi);

    // const storagePIDS = JSON.parse(sessionStorage.getItem('product_ids'));
    // !storagePIDS ? setProductIds({'30-subscription': [], '30-payment':[], '60-payment': []}): setProductIds(storagePIDS);  // need to sort
  }, []);

  const handleMake = async () => {
    // store the chosen price
    sessionStorage.setItem('chosenPrice', JSON.stringify(Number((plans.find(obj => obj.tag === planVariables.tag && obj.length === planVariables.length)).price)));

    const reqBody = {
      tag: planVariables.tag,
      length: Number(planVariables.length),
      session_ids: sessionIds,
      // product_id: productIds[plan],
    };

    const res = await fetch(`${API()}/make_order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    });

    const data = await res.json();
    // productIds[plan] = data.data.product_ids;
    // sessionStorage.setItem('product_ids', JSON.stringify(productIds));
    
    TiktokPixel.track("Checkout")
    
    window.location.href = data.data.stripe_url;
  };

  const plans = [
    {
      labelTop: '30 Day Subscription',
      tag: 'subscription',
      length: '30',
      price: sumPrice30,
      oldPrice: (sumPrice30 * 1.3).toFixed(2),
      pricePerDay: (sumPrice30 / 30).toFixed(2),
      benefits: [
        'Subscribe and save 30% ',
        'Free shipping (worth £2.99). Always',
        '30 day serving billed and delivered monthly',
        ...prices.map((p) => `Uniquely tailored supplement for ${p.dogName}`),
      ],
    },
    // {
    //   labelTop: '60 Day Price Plan (billed every 60 days)',
    //   plan: '60',
    //   price: sumPrice60,
    //   oldPrice: Number.parseFloat(sumPrice60 + sumDiscount).toFixed(2),
    //   benefits: [
    //     // 'Every 2 months ',
    //     '60 day serving',
    //     'Billed and delivered every 2 months',
    //     `Just £${(sumPrice60 / 60).toFixed(2)}p per day`,
    //     ...prices.map((p) => `Uniquely tailored supplement for ${p.dogName}`),
    //   ],
    //   tag: 'Save 10% a month',
    // },
    {
      labelTop: 'Buy once (30 day)',
      tag: 'payment',
      length: '30',
      price: (sumPrice30 * 1.3).toFixed(2),
      oldPrice: undefined,
      pricePerDay: ((sumPrice30 * 1.3) / 30).toFixed(2),
      benefits: [
        '30 day serving',
        ...prices.map((p) => `Uniquely tailored supplement for ${p.dogName}`),
      ],
    },
    // {
    //   labelTop: 'Buy once (60 day)',
    //   plan: '60-payment',  // or have a separate tag
    //   tag: 'payment',
    //   length: '60',
    //   price: ((sumPrice60 * 1.1) + 2.99).toFixed(2),  // need to add dynamic percentage/value
    //   oldPrice: (((sumPrice60 * 1.1) / 0.9) + 2.99).toFixed(2),  // need to add dynamic percentage/value
    //   pricePerDay: ((sumPrice60 * 1.1) / 60).toFixed(2),
    //   benefits: [
    //     // 'Per month',
    //     '60 day serving',
    //     'save 10% on 30 day buy once',
    //     ...prices.map((p) => `Uniquely tailored supplement for ${p.dogName}`),
    //   ],
    // },
  ];

  let chosenTagPlans = plans.filter(plan => plan.length === planVariables.length);

  const startedKitIncludes = [
    'Free personalised tin',
    '30 day serving',
    'Free 24/7 vet access',
    'Free consultation with our Head Vet',
    'Free made to measure spoon',
    '90 day money-back guarantee if you do not see results',
  ];

  const listWithCheckMarks = [
    'Free shipping with subscription',
    'Free formula changes if required',
    'Cancel or adjust shipments anytime',
  ];

  const footerImages = [
    {
      img: SliderImage4,
      comment: {
        stars: 5,
        text: 'This Dog is Unique has been great for our little rescue, Ginni. She absolutely loves the taste of the powder and I’ve seen a noticeable difference in her tummy and breath!',
        author: 'Julie M',
        petName: 'Ginni',
      },
    },
    {
      img: SliderImage5,
      comment: {
        stars: 5,
        text: 'I was worried that Teazel wouldn’t eat her supplement as she’s famously picky, but she loves it! Since starting TDIU I’ve really noticed that she’s been less anxious around other dogs and her sensitive stomach has settled too. Safe to say she will be a lifelong subscriber!',
        author: 'Elaine M',
        petName: 'Teazel',
      },
    },
    {
      img: SliderImage6,
      comment: {
        stars: 5,
        text: 'I was surprised that Panda liked it and shocked to see that he’s stopped barking on the tube!!',
        author: 'Ginny W',
        petName: 'Panda',
      },
    },
    {
      img: SliderImage7,
      // comment: {
      //   stars: 5,
      //   text: 'No comments yet',
      //   author: '#author_name',
      //   petName: '#pet_name',
      // },
    },
  ];

  const getHeaderText = () => {
    let dogsNames = '';
    if (prices.length === 1) {
      dogsNames = `${prices[0].dogName}'s`;
    } else if (prices.length === 2) {
      dogsNames = `${prices[0].dogName} and ${prices[1].dogName}'s`;
    } else if (prices.length > 2) {
      dogsNames = `${prices
        .map((item, idx) =>
          idx === prices.length - 1
            ? `and ${item.dogName}'s`
            : `${item.dogName}, `,
        )
        .join('')}`;
    }

    return `${dogsNames} Unique Supplement Starter Kit`;
  };

  return (
    <section className="container mx-auto mt-[72px] flex h-screen w-screen flex-col px-6 py-6 md:px-12 lg:flex-row">
      <main className="">
        <div className="md:hidden">
          <QuizSelectPlanSlider
            images={[SliderImage1, SliderImage2, SliderImage3]}
          />
        </div>

        <div className="relative md:flex md:gap-16">
          <div className="h-fit md:sticky md:top-0 md:max-w-[668px]">
            <Heading size={Sizes.h3} className="mt-[14px]">
              {getHeaderText()}
            </Heading>
            <div className="mt-2 flex flex-col gap-4">
              <Text weight={TextWeights.semibold}>
                Your dog’s completely unique supplement has been specially
                designed by our Head Vet to provide the correct level of support
                for your dog.
              </Text>
              <div>
                <Text weight={TextWeights.semibold}>Includes:</Text>
                <ul className="ml-6 list-disc">
                  {startedKitIncludes.map((item, idx) => (
                    <li key={idx}>
                      <Text weight={TextWeights.semibold}>{item}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="mt-6 flex flex-col gap-8">
              {chosenTagPlans.map((item, idx) => (
                <QuizSelectPlanItem
                  key={idx}
                  labelTop={item.labelTop}
                  tag={item.tag}
                  length={item.length}
                  price={item.price}
                  oldPrice={item.oldPrice}
                  pricePerDay={item.pricePerDay}
                  benefits={item.benefits}
                  chosen={planVariables.tag === item.tag && planVariables.length === item.length}
                  onClick={() => setPlanVariables({'tag': item.tag, 'length': item.length})}
                />
              ))}
            </div>

            <button
              className="mt-8 block w-full bg-dorange-400 focus:bg-dorange-100 p-2 font-title text-xl font-semibold text-white"
              onClick={() => {
                handleMake();
              }}>
              Get Started
            </button>
            <Link
              to={``}
              className="block w-full text-center font-title text-sm font-semibold underline mt-2">
              (25% off your first order by using WOOF25 on the next page)
            </Link>

            <ul className="mt-[18px]">
              {listWithCheckMarks.map((item, idx) => (
                <li key={idx} className="flex items-center gap-2">
                  <TickSvgIcon width="9px" height="9px" />
                  <Text
                    className="text-[14px]"
                    weight={TextWeights.semibold}
                    font={TextFonts.fontTitle}>
                    {item}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex max-w-[50%] flex-col max-md:hidden">
            <img src={SliderImage1} alt="img1" />
            <img src={SliderImage2} alt="img2" />
            <img src={SliderImage3} alt="img3" />
          </div>
        </div>

        <div className="mt-8 mb-6 md:hidden">
          <QuizSelectPlanSlider
            images={footerImages.map((i) => i.img)}
            comments={footerImages.map((i) => i.comment)}
          />
        </div>

        <div className="mt-8 mb-6 flex gap-4 pb-6 max-lg:flex-wrap md:max-lg:justify-between max-md:hidden">
          {footerImages.map((image, idx) => (
            <div className="flex w-[24%] flex-col max-lg:w-[48%]">
              <img src={image.img} alt={`img-${idx + 1}`} />
              {image.comment && (
                <QuizSelectPlanImageComment comment={image.comment} />
              )}
            </div>
          ))}
        </div>
      </main>
    </section>
  );
};
export default QuizSelectPlan;
