import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizJointCareFour = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.joint_q4) {
      setOption(info.joint_q4);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'joint-care-4' });
      sessionStorage.setItem(
        'current_health_issue',
        JSON.stringify('joint-care'),
      );
    } 
  }, [si]);

  const [option, setOption] = useState(1);
  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];
  const handleClick = () => {
    if (option) {
      updateQuizInfo({ joints_q4: option });
      updateHealthIssuesAnswers({ joints_q4: option });
      navigate(`/quiz/joint-care-5/?si=${si}`);
    }
  };

  const dotDetailsData = [
    {
      title: 'Never',
      body: 'My dog never limps, or occasionally does but it self-resolves quickly',
    },
    {
      title: 'Once a day',
      body: 'My dog limps relatively often - this may be exacerbated by exercise',
    },
    {
      title: 'More than once a day',
      body: 'My dog always has a noticeable limp or imbalance in the way they walk',
    },
  ];
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="px-9 container mx-auto flex h-screen flex-col items-center justify-center gap-9">
        <QuizSlideHeading before={`Does ${name}`} title="Have a limp?" />
        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation handleBack={()=>navigate(-1)}   handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizJointCareFour;
