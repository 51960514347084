import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import AltButton from "pages/App/AltButton/AltButton";
import API from "pages/App/API/API";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logo.svg";

import "./Prelaunch.scss";

const Prelaunch = () => {
  const [email, setEmail] = useState("");
  const [gdpr, setGdpr] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const [message, setMessage] = useState(
    "Email not recognised, please try a different email"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const parameters = JSON.stringify({
        email: email,
        gdpr: gdpr,
      });
      const customConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API()}/promotionals/email`,
        parameters,
        customConfig
      );
      setMessage(res.data.message);
      setLoader(false);
      setOpen(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      if (err.response.status === 412) {
        setMessage(err.response.data.message);
      } else {
        setMessage("Email not recognised, please try a different email");
      }
      setLoader(false);
      setOpen(true);
      console.log(err);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="prelaunch">
        <img onClick={() => navigate("/")} src={Logo} alt="Logo" />
        <h3>Your email</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni, quis
          voluptas! Reprehenderit quasi.
        </p>
        <form action="#" onSubmit={(e) => handleSubmit(e)}>
          <input
            type="email"
            placeholder="woof@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="prelaunch__email-input"
          />
          <label className="prelaunch__checkbox">
            <input
              value={gdpr}
              onChange={() => setGdpr(!gdpr)}
              id="pl__checkbox"
              type={"checkbox"}
            />
            <span class="checkmark"></span>
            <span className="text"> I want to receive product news.</span>
          </label>
          {loader && (
            <div
              style={{ marginTop: "20px", textAlign: "start" }}
              class="dot-falling"
            ></div>
          )}
          <AltButton type={"submit"}>Subscribe now</AltButton>
        </form>
      </div>
    </>
  );
};

export default Prelaunch;
