import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import getName from 'utils/getName';
import getSi from 'utils/getSi';
import updateQuizInfo from 'utils/updateQuizInfo';
import { useDispatch, useSelector } from 'react-redux';

import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contextName = useSelector((state) => state.quiz.name);

  const si = getSi();

  const [name, setName] = useState('');

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'confirmEmail' });
    } 
  }, [si]);

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await getName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  const handleClick = () => {
    navigate(`/quiz/dogGender/?si=${si}`);
  };

  return (
    <section className="h-screen bg-dorange-100">
      <div className="container mx-auto flex h-screen max-w-5xl flex-col items-center justify-center gap-9 px-9">
        <QuizSlideHeading
          title={`Amazing! Let’s see what we can do for ${name}`}
          after="This quiz should take about 5 minutes to complete, and will lead you through a series of questions aimed to better understand your dog’s health needs and create a formula that is unique to them."
        />
        <QuizSlideNavigation handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizConfirmEmail;
