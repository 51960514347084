import React from 'react';

import Heading, { Sizes } from '../../../components/typography/Heading';
import Text, { TextColors, TextSizes } from '../../../components/typography/Text';

import "./StatsItem.scss";

const StatsItem = ({ stat, description }) => {
  return (
    <div className="stats-item flex gap-2.5 md:gap-5 items-center">
      <div className="circle bg-dteal-50 text-2xl md:text-4xl lg:text-6xl">
        <Heading size={Sizes.h2}>{stat}</Heading>
      </div>
      <Text color={TextColors.white} size={TextSizes.sm} className="min-w-[11rem] block">{description}</Text>
    </div>
  )
}

export default StatsItem