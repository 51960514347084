import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import getSi from 'utils/getSi';
import updateQuizInfo from 'utils/updateQuizInfo';

import API from 'pages/App/API/API';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizDogBreedDetail = () => {
  const navigate = useNavigate();
  const si = getSi();
  
  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'dogBreedDetail' });
    } 
  }, [si]);
  
  /* --- This code is used to display "You tol us that {name} is a {breed}" message
  const [descrip, setDescrip] = useState('');
  useEffect(() => {
    const getBreed = async () => {
      try {
        const res = await axios.get(`${API()}/quiz/${si}`);

        console.log(res, "quiz si");
        const name = await res.data.data.dog_name;
        const breed = await res.data.data.breeds;
        console.log(breed[0], "BreedDetail")
        if (breed.length === 1) {
          setDescrip(`You told us that ${name} is a <span>${breed[0].Breed}<span/>`);
        } else if (breed.length === 2) {
          setDescrip(
            `You told us that ${name} is a mix of <span>${breed[0].Breed}</span> and <span>${breed[1].Breed}</span>`,
          );
        } else if (breed.length === 3) {
          setDescrip(
            `You told us that ${name} is a mix of <span>${breed[0].Breed}</span>, <span>${breed[1].Breed}</span> and <span>${breed[2].Breed}</span>`,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

     getBreed();
  }, [descrip]);
 */

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="px-9 lg:max-w-[73rem] container mx-auto flex h-screen flex-col items-center justify-center gap-9">
        <QuizSlideHeading 
          titleQuote={{ 
              text: 'These next questions have been designed by a veterinarian and reflect what would be asked in an in-person consultation',
              author: ''
          }} 
        />
        <QuizSlideNavigation
          handleNext={() => navigate(`/quiz/dogWeight/?si=${si}`)}
        />
      </div>
    </section>
  );
};

export default QuizDogBreedDetail;