import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GetName from 'utils/getName';
import getSi from 'utils/getSi';
import nextIssue from 'utils/nextIssue';
import updateHealthIssuesAnswers from 'utils/updateHealthIssuesAnswers';
import updateQuizInfo, { getQuizInfo } from 'utils/updateQuizInfo';
import DotDetails from '../DotDetails/DotDetails';
import DotSelector from '../DotSelector/DotSelector';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizAnxietyFive = () => {
  const navigate = useNavigate();
  const si = getSi();

  const [name, setName] = useState('');
  const contextName = useSelector((state) => state.quiz.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dogName = async () => {
      if (contextName) {
        setName(contextName);
      } else {
        const n = await GetName(si, dispatch);
        setName(n);
      }
    };
    dogName();
  }, [si]);

  useEffect(() => {
    const info = getQuizInfo();
    if (info?.stress_and_anxiety_q5) {
      setOption(info.stress_and_anxiety_q5);
    }
  }, []);

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'anxiety-5' });
      sessionStorage.setItem('current_health_issue', JSON.stringify('anxiety'));
    } 
  }, [si]);

  const [option, setOption] = useState(1);
  const dotSelectorData = [{ name: 1 }, { name: 2 }, { name: 3 }];

  const dotDetailsData = [
    {
      title: 'Not at all',
      body: 'My dog isn’t usually bothered by loud noises, but every now and then they can become startled',
    },
    {
      title: 'Somewhat',
      body: 'My dog gets quite nervous and becomes unsettled by sudden or loud noises',
    },
    {
      title: 'Significantly',
      body: 'My dog is very reactive to loud noises and is noticeably frightened by them',
    },
  ];

  const handleClick = () => {
    if (option) {
      updateQuizInfo({ stress_and_anxiety_q5: option });
      updateHealthIssuesAnswers({ stress_and_anxiety_q5: option });
      nextIssue(navigate);
    }
  };

  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="container mx-auto flex h-screen flex-col items-center justify-center gap-12">
        <QuizSlideHeading
          before={`Does ${name}`}
          title="Get scared of loud noises?"
        />
        <div>
          <DotSelector
            data={dotSelectorData}
            option={option}
            setOption={setOption}
            details={dotDetailsData}
          />
          <DotDetails details={dotDetailsData} />
        </div>
        <QuizSlideNavigation handleBack={()=>navigate(-1)}  handleNext={handleClick} />
      </div>
    </section>
  );
};

export default QuizAnxietyFive;
