import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const TextSizes = {
  base: 'base',
  sm: 'sm',
  sm_2: 'sm_2',
  sm_3: 'sm_3',
  md: 'md',
};

export const TextColors = {
  teal: 'teal',
  white: 'white',
};

export const TextWeights = {
  regular: 'regular',
  semibold: 'semibold',
};

export const TextFonts = {
  fontBody: 'font-body',
  fontTitle: 'font-title'
}

const fontClasses = {
  [TextFonts.fontBody]: 'font-body',
  [TextFonts.fontTitle]: 'font-title',
}

const sizeClasses = {
  [TextSizes.sm]: 'text-xs md:text-sm',
  [TextSizes.sm_2]: 'text-[14px] md:text-sm',
  [TextSizes.sm_3]: 'text-[15px] md:text-xl',
  [TextSizes.base]: 'text-sm md:text-base',
  [TextSizes.md]: 'lg:text-[10px] md:text-[16px] sm:text-[10px]',
};

const colorClasses = {
  [TextColors.teal]: 'text-dteal-400',
  [TextColors.white]: 'text-white',
};

const weightClasses = {
  [TextWeights.regular]: 'font-regular',
  [TextWeights.semibold]: 'font-semibold',
};

const Text = (props) => {
  const color = colorClasses[props.color || TextColors.teal];
  const size = sizeClasses[props.size || TextSizes.base];
  const weight = weightClasses[props.weight || TextWeights.regular];
  const fontFamily = fontClasses[props.font || TextFonts.fontBody]

  const classes = classNames(fontFamily, color, size, weight, props.className);
  return (
    <p
      className={classes}
      dangerouslySetInnerHTML={{ __html: props.children }}></p>
  );
};

Text.propTypes = {
  size: PropTypes.oneOf(Object.values(TextSizes)),
  color: PropTypes.oneOf(Object.values(TextColors)),
  className: PropTypes.string,
};

export default Text;
