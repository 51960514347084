import axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getSi from 'utils/getSi';
import updateQuizInfo from 'utils/updateQuizInfo';
import pluralize from 'pluralize';

import API from 'pages/App/API/API';
import QuizSlideHeading from '../QuizSlideHeading/QuizSlideHeading';
import QuizSlideNavigation from '../QuizSlideNavigation/QuizSlideNavigation';

const QuizDogDetail = () => {
  const navigate = useNavigate();
  const si = getSi();
  const [descrip, setDescrip] = useState('');

  useEffect(() => {
    if (si) {
      updateQuizInfo({ slide: 'dogDetail' });
    } 
  }, [si]);

  useEffect(() => {
    const getBreed = async () => {
      try {
        const res = await axios.get(`${API()}/quiz/${si}`);

        const name = await res.data.data.dog_name;
        const breed = await res.data.data.breeds;
        const age = await res.data.data.age;

        if (breed.length === 1) {
          setDescrip(
            `You told us that ${name} is a${
              Math.floor(age / 12)
                ? ' ' +
                  Math.floor(age / 12) +
                  ' ' +
                  pluralize('year', Math.floor(age / 12))
                : ''
            }${
              age % 12
                ? (Math.floor(age / 12) && age % 12 ? ', ' : ' ') +
                  (age % 12) +
                  ' ' +
                  pluralize('month', age % 12)
                : ''
            } old  ${breed[0].Breed}`,
          );
        } else if (breed.length === 2) {
          setDescrip(
            `You told us that ${name} is a  ${
              Math.floor(age / 12)
                ? ' ' +
                  Math.floor(age / 12) +
                  ' ' +
                  pluralize('year', Math.floor(age / 12))
                : ''
            }${
              age % 12
                ? (Math.floor(age / 12) && age % 12 ? ', ' : ' ') +
                  (age % 12) +
                  ' ' +
                  pluralize('month', age % 12)
                : ''
            } old  ${breed[0].Breed} and ${breed[1].Breed}`,
          );
        } else if (breed.length === 3) {
          setDescrip(
            `You told us that ${name} is a  ${
              Math.floor(age / 12)
                ? ' ' +
                  Math.floor(age / 12) +
                  ' ' +
                  pluralize('year', Math.floor(age / 12))
                : ''
            }${
              age % 12
                ? (Math.floor(age / 12) && age % 12 ? ', ' : ' ') +
                  (age % 12) +
                  ' ' +
                  pluralize('month', age % 12)
                : ''
            } old  ${breed[0].Breed}, ${breed[1].Breed}  and ${breed[2].Breed}`,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBreed();
  }, [descrip]);
  return (
    <section className="min-h-screen bg-dteal-50 pt-24 md:pt-4">
      <div className="px-9 lg:max-w-xl container mx-auto flex h-screen flex-col items-center justify-center gap-9">
        <QuizSlideHeading title={HTMLReactParser(descrip)} />
        <QuizSlideNavigation
          handleNext={() => navigate(`/quiz/selectHealthIssues/?si=${si}`)}
        />
      </div>
    </section>
  );
};

export default QuizDogDetail;
